@import '../../../assets/styles/colors.scss';

.alerta-mensagem__container {
	display: grid;
	align-items: center;
	justify-content: center;
	justify-self: center;
	

	&__mensagem {
		span{
		font-weight: bold;
		font-size: 20px;
		line-height: 25px;
		text-align: center;
		color: $secundary_color_700;
		padding-top: 12px;
		}
	}

	&__subMensagem {
		font-weight: 450px;
		font-size: 14px;
		line-height: 24px;
		text-align: center;
		color: $secundary_color_700;
		padding-top: 8px;
	}
	&__icon{
		justify-content: center;
		text-align: center;
	}

	&__breakLine{
		white-space: pre-line;
		display: block;
		padding-top: 0px !important;
	}

}
