@import 'assets/styles/colors.scss';

.load-more-btn {
    width: 100%;
    display: flex;
    margin: 32px 0;
    justify-content: center;
}

#load-more-btn-btn {
    box-sizing: border-box;
    height: 33px;
    width: 197px;
    border: 1px solid rgba(58,61,66, 0.1);
    border-radius: 8px;
    color: #3A3D42;
    background-color: white;
    cursor: pointer;
    span {
        height: 15px;
        opacity: 0.6;
        color: #3A3D42;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.21px;
        line-height: 15px;
    }
}

#load-more-btn-finalmsg {
    height: 28px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
}


#load-more-btn-green-btn {
    box-sizing: border-box;
    width: 150px;
    height: 38px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    color: #ffffff;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.25px;
    text-align: center;
    padding: 10px 24px;
    background: $primary_color_500;
    &:hover {
      background-color: $primary_color_600;
    }
      &:focus {
      background-color: $primary_color_700;
    }
}
