.dashboard-floor-plan__table{
	background: #EDF1F7;
	border-radius: 4px;
	padding: 16px 16px 0;
	font-style: normal;
	font-weight: 450;
	font-size: 12px;
	line-height: 16px;
	color: #7A7C9A;

	.title{
		font-style: normal;
		font-weight: 450;
		font-size: 12px;
		line-height: 16px;
		color: #555770;
	}

	.dashboard-floor-plan__table__row{
		display: grid;
		grid-template-columns: minmax(0, 2fr) minmax(0, auto) minmax(0, auto) minmax(0, auto);
		grid-template-areas: "produto vencido  aVencer total";
		column-gap: 8px;
		padding-bottom: 16px;

		.dashboard-floor-plan__table_produto{
			grid-area: produto;
			display: flex;
			align-items: flex-start;
			min-width: 100px;
		}
	
		.dashboard-floor-plan__table_a-vencer{
			grid-area: aVencer;
			display: flex;
			align-items: flex-start;
			min-width: 100px;
		}
	
		.dashboard-floor-plan__table_vencido{
			grid-area: vencido;
			display: flex;
			align-items: flex-start;
			min-width: 100px;
		}
	
		.dashboard-floor-plan__table_total{
			grid-area: total;
			display: flex;
			align-items: flex-start;
			min-width: 100px;
		}
	}
}
