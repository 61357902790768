.dashboard-floor-plan__filter__content{
	display: grid;
	width: 100%;
	grid-gap: 8px;
	grid-template-columns: 323px 252px 252px 160px;
	grid-template-rows: auto;
	grid-template-areas: "pontos produto data botao";

	&_produto{
		grid-area: produto;
		margin-top: 2px;
	}

	&_ponto{
		grid-area: pontos;
		margin-top: 2px;
	}

	&_data{
		grid-area: data;
	}

	&_botao{
		grid-area: botao;
		margin-top: 19px;
	}

	.dashboard__page__filter__content_loading-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
	}
}
