.dashboard-page__page {
  display: grid;
  height: 100%;

  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 
  "menu content";
}

.dashboard-page__menu {
  grid-area: menu;
  width: 240px;
}

.dashboard-page__content {
  grid-area: content;
  overflow-x: auto;
}
