.common__comentarios-list__content_outer-border {
    border-top: solid 1px #edf1f7;
}

.common__comentarios-list__content_label {
    border-left: solid 1px #edf1f7;
    color: #8f9bb3;
    font-size: 11px;
    height: 30px;

    display: flex;
    align-items: center;

    margin-left: 12px;
    padding-left: 15px;
    padding-top: 3px;
}

