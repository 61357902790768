.carteira-page__concessionaria{
	font-size: 12px;
	line-height: 16px;
	color: #8F9BB3;

	&__nome{
		display: block;
		font-size: 14px;
		line-height: 24px;
		color: #555770;
	}
}
