.pagamento-page {
	display: grid;
	height: 100%;

	grid-template-columns: min-content 1fr;
	grid-template-rows: auto auto 1fr;
	grid-template-areas: 
			"menu content";
}

.pagamento-page_menu{
	grid-area: menu;
	width: 100%;
	height: 100%;
	overflow-y: auto;
}

.pagamento-page_content{
	grid-area: content;
	max-height: 100%;
	// overflow-y: auto;

	.pagamento-page__header{
		display: flex;
		align-items: center;
		justify-content: space-between;

		&__button{
			margin-right: -20px;
		}
	}

	.pagamento-page__body{
		display: grid;
		padding: 0 24px 0px 48px;
		column-gap: 56px;
		grid-template-rows: auto;
		grid-template-columns: auto 360px;
		grid-template-areas: " lista resumo";
		height: 100%;

		&__list{
			grid-area: lista;
			font-size: 14px;
			line-height: 24px;
			color: #8F9BB3;
			height: 100%;
		}

		&__resumo{
			grid-area: resumo;
			height: 100%;
		}

		&__resumo-button{
			height: 40px;
			width: 100%;
		}
	}
}
