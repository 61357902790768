@media print{

  .no-print{

    display: none;

  }
}
@mixin scrollbar {
  ::-webkit-scrollbar {
    width: 8px;
    background: white;
    border-radius: 8px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.2);
  }
  
  ::-webkit-scrollbar-thumb:hover{
    background-color: rgba(0, 0, 0, 0.4)!important;
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(0, 0, 0, 0.1);
  }
}

* {
  @include scrollbar;
}