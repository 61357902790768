.comunicados__container__body{
	height: 100%;
	display: flex;
  flex-direction: column;
  align-items: stretch;

	.list__inner-div{
		height: auto;
	}

	&__cards{
		padding: 20px 40px 0;
		display: flex;
		flex-wrap: wrap;
		height: 100%;
	}
}

