.modal-historico__container{
	padding: 24px 28px 24px 28px;
	width: 100%;

	&__titulo{
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 28px;
		letter-spacing: -0.02px;
		color: #555770;
		margin-bottom: 52px;
	}
}
