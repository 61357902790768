.payment-list {
  height: 100%;
  position: relative;
}

.payment-list-container {
  max-height: 85%;
  min-height: 85%;
  overflow-y: auto;
  min-width: 492px;
}

.payment-list-footer {
  height: 15%;
  width: 100%;
  position: absolute;
  bottom:0;

  text-align: center;
  vertical-align: middle;
  
  display: grid;
  grid-template-columns: 40% 12px auto 1fr auto auto;
  grid-template-areas: 
    "count-area . value-area . btn-edit-area btn-pay-area";
}

.payment-footer-count {
  min-width: 150px;
  grid-area: count-area;
  margin: auto;
  margin-left: 48px;
  text-align: left;
}

.payment-footer-total {
  grid-area: value-area;
  margin: auto;
  text-align: left;
}

.payment-footer-edit {
  grid-area: btn-edit-area;
  height: 38px;
  margin-right: 24px;
  margin-left: 24px;
  width: 100px;
}

.payment-footer-pay {
  grid-area: btn-pay-area;
  height: 38px;
  margin-right: 48px;
  width: 75px;
}
