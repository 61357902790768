.iof__page {
	display: grid;
	height: 100vh;
	overflow: hidden;

	grid-template-columns: min-content 1fr;
	grid-template-rows: auto auto auto 1fr;
	grid-template-areas: 
			"menu title"
			"menu legal"
			"menu filters"
			"menu list";
}

.iof__menu-container {
	grid-area: menu;
	width: 100%;
	height: 100%;
	overflow-y: auto;
}

.iof__page-title {
	grid-area: title;
}

.iof__legal-text {
	grid-area: legal;
	margin-top: 16px;
	margin-left: 48px;
	margin-right: 48px;
	margin-bottom: 12px;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: rgba(0, 0, 0, 0.6);
}

.iof__filter__content{
	grid-area: filters;
	margin-left: 48px;
	margin-right: 48px;
	margin-bottom: 12px;
	z-index: 10;
}

.iof__list {
	grid-area: list;
	overflow-x: auto;
}

.iof__error {
	grid-area: list;
	margin-top: 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.iof__page-title-error {
	grid-area: title;
	margin-top: 48px;
	margin-bottom: 12px;
	margin-left: 48px;
	font-size: 34px;
	font-weight: 900;
	color: rgba(0, 0, 0, 0.82);
}
.iof__page-spinner{
	width: 100%;
	padding: auto auto;
	padding-top: 112px;
	display: flex;
	justify-content: center;
}

.iof__alerta-mensagem{
	width: 100%;
	margin: 200px 48px 12px 0;
	display: flex;
	align-items: center;
	justify-content: center;
}
