.payment-editor-comp {
  height: 100%;
  width: 100%;
  position: relative;
}

.payment-editor-header-row {
  margin: 8px 48px;
  padding: 0;
  height: 20px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.6);
  
  display: grid;
  
  grid-template-columns: 48px 210px 150px 146px 28px 130px 28px 150px;
  grid-template-rows: auto;
  grid-template-areas: 
    ". . . due-date . total-value . paying-value";

  &-due {
    grid-area: due-date;
    text-align: left;
  }

  
  &-initial-value {
    grid-area: total-value;
    text-align: right;
    margin-right: 22px;
  }

  
  &-payment-value {
    grid-area: paying-value;  
    margin: auto 18px auto;
    margin-left: 36px;
  }
  
}

.editor-list {
  height: 85%;
  vertical-align: middle;
  font-size: 20px;
}

.editor-footer {
  height: 15%;
  width: 100%;
  position: absolute;
  bottom: 0;
  vertical-align: middle;
  display: grid;
  grid-template-columns: auto 1fr auto 0.5fr auto auto;
  grid-template-areas: 
    "count-area . value-area . btn-back btn-confirm";
}

.editor-count {
  grid-area: count-area;
  margin: auto 0px;
  margin-left: 48px;
}

.editor-total-value {
  grid-area: value-area;
  margin: auto 0px;
  padding-left: 48px;
}

.editor-btn-back {
  grid-area: btn-back;
  height: 38px;
  width: 100px;
  margin-right: 20px;
}

.editor-btn-confirm {
  grid-area: btn-confirm;
  height: 38px;
  width: 75px;
  margin-right: 48px;
}
