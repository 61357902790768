.confirmation-list-container {
  height: 100%;
  margin-top: 16px;
  

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.25px;

  display: grid;
  grid-template-rows: 48px 1fr;
  grid-template-columns: 48px auto 1fr 120px 16px 120px 16px 120px 16px 48px;
  grid-template-areas:
    ". hd-inv . hd-due . hd-balance . hd-pay sidebar ."
    ". list list list list list list list list .";
}

.confirmation-list-header {
  justify-self: left;
  vertical-align: middle;
  padding-right: 16px;
  margin: auto 0;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.6);


  &-inv {
    margin-left: 16px;
    grid-area: hd-inv;
    min-width: 100px;
  }

  &-due {
    grid-area: hd-due;
  }

  &-balance {
    grid-area: hd-balance;
  }

  &-pay {
    grid-area: hd-pay;
    justify-self: right;
  }
}

.confirmation-rows-container {
  grid-area: list;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.confirmation-row {
  padding-bottom: 16px;
  padding-top: 16px;
  padding-right: 16px;
  border-bottom: 1px solid #EBEBEB;
  border-radius: 0.5px;
  transition: 0.4s;

  display: grid;
  grid-template-columns: auto 1fr 120px 16px 120px 16px 120px;
  grid-template-areas: 
    "number . date . total-value . paying-value";

  &:hover {
    background-color: rgba(236,236,236, 0.35);
  }
}

.confirmation-row-number {
  grid-area: number;
  margin-left: 16px;

  white-space: nowrap;
  color: rgba(0, 0, 0, 0.82);
}

.confirmation-row-date {
  grid-area: date;
}

.confirmation-row-total-value {
  grid-area: total-value;
  justify-self: left;
  margin: auto 0;
}

.confirmation-row-paying-value {
  grid-area: paying-value;

  color: rgba(0,0,0,0.82);
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0.1px;
	line-height: 24px;
  text-align: right;
  margin: auto 10px auto;
}
