
@font-face {
  font-family: 'CircularStd';
  font-style: italic;
  font-weight: 900;
  src:  url('./assets/font/CircularStd-BlackItalic.otf')  format('opentype');
}

@font-face {
  font-family: 'CircularStd';
  font-weight: 900;
  font-style: normal;
  src:  url('./assets/font/CircularStd-Black.otf')  format('opentype');
}

@font-face {
  font-family: 'CircularStd';
  font-style: italic;
  font-weight: medium;
  src:  url('./assets/font/CircularStd-MediumItalic.otf')  format('opentype');
}

@font-face {
  font-family: 'CircularStd';
  font-weight: medium;
  font-style: normal;
  src:  url('./assets/font/CircularStd-Medium.otf')  format('opentype');
}

@font-face {
  font-family: 'CircularStd';
  font-weight: bold;
  font-style: italic;
  src: url('./assets/font/CircularStd-BoldItalic.otf') format('opentype'),
}

@font-face {
  font-family: 'CircularStd';
  font-weight: bold;
  font-style: normal;
  src: url('./assets/font/CircularStd-Bold.otf') format('opentype'),
}

@font-face {
  font-family: 'CircularStd';
  font-weight: normal;
  font-style: italic;
  src: url('./assets/font/CircularStd-BookItalic.otf') format('opentype'),
}

@font-face {
  font-family: 'CircularStd';
  font-weight: 500;
  font-style: normal;
  src: url('./assets/font/CircularStd-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'CircularStd';
  font-weight: 300;
  font-style: normal;
  src: url('./assets/font/CircularStd-Book.otf') format('opentype');
}

@font-face {
  font-family: 'CircularStd';
  font-weight: 400;
  font-style: normal;
  src: url('./assets/font/CircularStd-Book.otf') format('opentype');
}

@font-face {
  font-family: 'CircularStd';
  font-weight: normal;
  font-style: normal;
  src: url('./assets/font/CircularStd-Book.otf') format('opentype');
}


body {
  margin: 0;
}

* {
  font-family: CircularStd !important;
}
