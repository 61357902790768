.mensagem-rede__modal {
  &__overlay {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background-color: rgba(85, 87, 112, 0.4);

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__container {
    width: 644px;
    height: 360px;
    overflow-x: hidden;

    background-color: white;
    border-radius: 8px;
    position: relative;

    display: grid;
    grid-template-columns: 644px;
    grid-template-rows: 5px 16px 1fr 16px;
    grid-template-areas:
      "header "
      "."
      "content "
      ".";

    &__header {
      grid-area: header;
      border-radius: 8px 8px 0px 0px;
      background: #00afad;
    }

    &__close {
      z-index: 1000;
      position: absolute;
      top: 20px;
      right: 20px;

      width: 36px;
      height: 40px;
      color: #555770;
    }

    &__content {
      grid-area: content;
      height: 100%;
    }
  }
}
