.payment-summ-container {
  min-width: 500px;

  background-color: #f9fafc;
  border-radius: 14px;

  display: grid;
  grid-template-columns: 24px 1fr 48px 1fr 24px;
  grid-template-rows: auto 1fr;
  grid-template-areas: 
    ".  header   header header header"
    ".  col-left .      col-right  . ";

  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.payment-summ-header {
  grid-area: header;
  align-self: center;

  margin-top: 24px;
  margin-bottom: 8px;

  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.82);
}

.payment-summ-col-left {
  grid-area: col-left;
  flex-direction: column;

}

.payment-summ-col-left-bottom {
  height: 48px;
  margin-top: 24px;
  vertical-align: bottom;

  border-top: solid 2px #979797;
  display: flex;
  padding: 4px;

  font-size: 16px;
  line-height: 1.75;
  color: rgba(0, 0, 0, 0.82);

}

.payment-summ-value-to-pay {
  flex: 0 0 40%;

  font-size: 16px;
  line-height: 1.75;
  color: rgba(0, 0, 0, 0.82);
}

.payment-summ-total {
  flex: 1;

  text-align: right;
  font-size: 20px;
  font-weight: 900;
}

.payment-summ-col-right {
  grid-area: col-right;
}

.payment-summ-col-right-bottom {
  height: 48px;
  margin-top: 24px;
  vertical-align: bottom;

  border-top: solid 2px #979797;
  display: flex;
  padding: 4px;

  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.82);
}

.payment-summ-item-row {
  padding: 12px 0;
  display: flex;

  font-size: 16px;
  line-height: 1.75;
}

.payment-summ-text-left {
  flex: 0 0 auto;

  color: rgba(0, 0, 0, 0.6);
}

.payment-summ-value-right {
  flex: 1;

  text-align: right;
  color: rgba(0, 0, 0, 0.82);
}
