.menu-divider {
    box-sizing: border-box;
    width: calc(100% - 48px);
    height: 1px;
    margin: 0 24px;
    background-color: #e9eaf0;
}

.menu-main {
    background-color: #F9FAFC;
    grid-area: main;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
    min-width: 240px;
}

.menu-main > .menu-balance {
    margin-top: 16px;
    margin-bottom: 16px;
    margin-right: 24px;
    margin-left: 24px;
}

.menu-nav-btns{
    position: relative;
    bottom: 0;
}

#menu-main-footer {
    bottom: 0;
    position: absolute;
}

#menu-main-footer > svg {
    width: 161px;
    height: 26px;
    margin: 24px 38px 24px 38px;
}