.chat-popover__popover-content-main__header__container > svg {
  width: 125px;
  height: 38px;
  margin: 16px 0 4px 16px;
}
.chat-popover__main-content-header__title{
  margin: 12px 0 0 18px;

  font-size: 24px;
  font-weight: 900;
  color: #ffffff;
}
.chat-popover__main-content-header__legend{
  margin: 8px 18px 0 18px;

  line-height: 1.33;
  color: #ffffff;
  font-size: 12px;
}

.chat-popover__main-content-header__x-container{
  position: relative;
  bottom: 35px;
  left: 280px;
}

.chat-popover__main-content-header__x-icon{
  cursor: pointer;
  color: #ffffff;
}