.erro-conexao{
    margin-top: 10%;
    &-container{
      width: 100%;
      margin: auto auto;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    &-image{
      height: 131px;
      width: 210px;
  
      &-bigger{
        width: 304px;
        height: 167px;
      }
      &-container{
        text-align: center;
        width: 100%;
      }
    }
    &-span{
      &-container{
        text-align: center;
        padding-bottom: 6px;
      }
      &-smaller{
        font-size: 16px;
        line-height: 1.69;
        color: rgba(0, 0, 0, 0.52);
      }
      &-bigger{
        font-size: 24px;
        font-weight: bold;
        line-height: 1.69;
        color: rgba(0, 0, 0, 0.52);
      }
    }
  }
