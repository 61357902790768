.td-acompanhamento__page__header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
	overflow-x: hidden;
}

.td-acompanhamento__page__root {
	overflow-x: hidden;
	display: grid;
	height: 100%;
	grid-template-rows: auto 1fr;
	grid-template-areas: 
	"filters"
	"list";
	min-width: 920px;
}

.td-acompanhamento__page__filters{
	grid-area: filters;
	display: flex;
	flex-direction: column;
}

.td-acompanhamento__page__list {
  grid-area: list;
}
