.dealer-selector {
    width: 100%;
    margin: auto auto;
    display: inline-block;
    vertical-align: center;
}

.dealer-selector-area{
    margin-left: 24px;
    margin-top: 24px;
    margin-bottom: 20px;
    min-width: 218px;
}

.dealer-selector-icon {
    display: inline-flex;
    margin-right: 6px;
    width: 32px;
    height: 32px;
    border-radius: 12px;
    //box-shadow: 0 2px 8px 0 #e9eaf0;
    //background-color: #ffffff;
    justify-items: center;
    justify-content: center;
    align-items: center;

    img {
        width: 20px;
    }
    &-search{
        display: inline-flex;
        margin-right: 6px;
        width: 32px;
        height: 32px;
        border-radius: 12px;
        box-shadow: 0 2px 8px 0 #e9eaf0;
        background-color: #ffffff;
        justify-items: center;
        justify-content: center;
        align-items: center;
            img {
                width: 14px;
            }
    }
}

.dealer-select {
    position: relative;
    display: inline-block;
    vertical-align: inherit;

    &-fix{
        vertical-align: 4px;
    }    
}
