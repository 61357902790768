@import 'assets/styles/colors.scss';

.inv-page-section-icon {
    margin: auto auto;
    height: 32px;
    width: 32px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inv-page-icon-red{
    background-color: $error_color_100_24;
}

.inv-page-icon-green {
    background-color: $terciary_color_100_24;
}

.inv-page-icon-blue {
    background-color: $primary_color_100_24;
}