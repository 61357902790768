@import 'assets/styles/colors.scss';

.solicitacao-page__toogle-container{
  width: 208px;
  height: 30px;
  margin-top:16px;

  background-color: $primary_color_100_36;

  border-radius: 20px;

  font-size: 12px;
  line-height: 16px;
}

.solicitacao-page__toogle-item{
  border-radius: 20px;
  height: 100%;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;

  outline: none;
}
