.comprovante__modal__container {
	height: 100%;
	width: 100%;
	display: table-row;
	grid-template-rows: 15% 85%;
	z-index: 1;
	grid-template-areas: "header content";
}

.comprovante__modal__content {
	// height: 100%;
	// border-right: 1px solid rgba(85, 87, 112, 0.1);
	padding: 0px 40px 40px 40px;
	display: grid;
	column-gap: 56px;
	grid-template-areas:
			"list details";
			
	&_list{
		grid-area: list;
    height: 100%;
    display: grid;
    grid-template-rows: 10% 12% 58% 20%;
    grid-template-areas:
				"subtitle"
        "list-header"
        "list-content"
        "alert";
		&_subtitle{
			grid-area: subtitle;
			margin-bottom: 32px;
			font-size: 14px;
			line-height: 24px;
			color: #8f9bb3;
		}
	}	

	&_aside{
		grid-area: details;
		height: 100%;
	}

}

.comprovante__modal__header {
  grid-area: header;
	width: 85%;
	padding: 36px 40px 13px 40px;
	// border-bottom: 1px solid rgba(85, 87, 112, 0.1);
	display: flex;
	height: 24px;
	color: #555770;
	font-size: 24px;
	font-weight: bold;
}

.comprovante__modal__list_header{
	grid-area: "list-header";
	display: grid;
	align-items: center;
	margin-top: 32px;
	grid-template-columns: 28% 25% 25% 22%;
	column-gap: 4px;
	border-bottom: 1px solid rgba(85, 87, 112, 0.1);
	font-size: 10px;
	line-height: 16px;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	color: #8F9BB3;
}

.comprovante__modal__list_chassi{
	font-size: 10px;
	line-height: 16px;
	color: #8F9BB3;
	overflow-wrap: break-word;
}

.comprovante__modal__list_row{
	grid-area: list-content;
	display: grid;
	align-items: center;
	grid-template-columns: 28% 25% 25% 22%;
	font-size: 10px;
	line-height: 16px;
	color: #8F9BB3;
	column-gap: 4px;
	margin-top: 12px;

	&-identificador {
		font-size: 14px;
		line-height: 24px;
		color: #555770;
		overflow: hidden;
		overflow-wrap: break-word;
		max-width: 156px;
  }

	&-origem {
		font-size: 14px;
		line-height: 24px;
		display: flex;
		flex-direction: column;
		color: #8F9BB3;
		align-self: start;
  }

	&-destino {
		font-size: 14px;
		line-height: 24px;
		display: flex;
		flex-direction: column;
		color: #8F9BB3;
		align-self: start;
  }

	&-valor {
		position: relative;
		font-size: 14px;
		line-height: 24px;
		/* identical to box height, or 171% */
		color: #555770;
		align-self: start;
  }
}

.comprovante__modal__card-resumo__card {
  width: 100%;
  min-height: 50px;

  background-color: rgba(228, 233, 242, 0.32);
  border-radius: 8px;

  color: #8f9bb3;
  font-size: 12px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &-lower {
    border-top: solid 1px rgba(85, 87, 112, 0.08);
    padding: 8px 16px;
  }

	&-lower-no-border {
    padding: 8px 16px;
  }
}

.comprovante__modal__card-resumo{
  text-align: center;
  padding: 32px 16px 16px;

  grid-template-columns: 1fr;
  grid-template-rows: 20px;
  display: grid;

  &__title {
		font-weight: bold;
    line-height: 2;
    font-size: 14px;
		align-items: center;
		padding: 16px 16px 0px 16px;

		color: #555770;
  }
}

.comprovante__modal__card__data-row {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;

  &-label {
    color: #555770;
  }

  &-value {
    max-width: 70%;
    text-align: right;
  }
}

.comprovante__modal__content_buttons{
	display: flex;
	column-gap: 8px;
	margin-top: 16px;
}

.comprovante__modal__content_botao_exportar{
	grid-area: exportar;
	display: flex;
	width: 100%;
	// visibility: hidden;
}

.comprovante__modal__content_botao_imprimir{
	grid-area: imprimir;
	display: flex;
	width: 46px;
}

@media print {
	.comprovante__modal__content {
		grid-template-columns: 1fr;
		grid-template-areas: 'details';
	}
}
