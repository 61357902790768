.atencao-mensagem__container{
	display: flex;
	padding: 12px;
	background: rgba(255, 182, 0, 0.08);
	border: 1px solid rgba(255, 182, 0, 0.32);
	border-radius: 4px;
	color: #BF8900;
	align-items: flex-start;
	white-space: normal;

	&__mensagem{
		white-space: normal;
		font-size: 12px;
		text-align: justify;
	}

	&__icon{
		display: flex;
		font-size: 24px;
		margin-right: 12px;
		align-items: center;
		justify-content: center;
		margin-left: -4px;
	}
}
