.card__container-comunicados{
	display: flex;
	flex-direction: column;
	width: 16.7%;
	background: #F7F9FC;
	border-radius: 4px;
	padding: 0 12px;
	margin: 0 16px 16px 0;
	height: fit-content;

	&__header{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 20px 0 12px;
		border-bottom: 1px solid rgba(85, 87, 112, 0.08);

		&__data-emissao{
				font-style: normal;
				font-weight: bold;
				font-size: 10px;
				line-height: 16px;
				text-align: center;
				color: #8F9BB3;
				padding: 4px 0 0;
				margin: 0;
		}
	}
	
	&__arquivo{
		display: flex;
		padding: 20px 0 16px;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		p{
			position: relative;
			padding: 8px 0 0;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 18px;
			text-align: center;
			color: #555770;
			word-break: break-all;
			height: 36px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 100%;
		}
	}

	&__button{
		justify-self: end;
		margin: 0;
		padding-bottom: 20px;
	}

}

.card__container-comunicados:hover {
	color: #fff;
	background-color: #FFFFFF;
	box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
	border-radius: 4px;
}
