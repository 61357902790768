.pedidos__modal_footer__container {
    display: grid;
    grid-template-columns: auto 1fr auto auto;
    grid-template-rows: 40px;
    grid-template-areas: "error . button1 button2";
  }
  
  .pedidos__modal_footer__error {
    grid-area: error;
    justify-self: start;
    padding-left: 16px;
  }
  
  .pedidos__modal_footer__button1 {
    grid-area: button1;
    margin-right: 8px;
  }
  
  .pedidos__modal_footer__button2 {
    grid-area: button2;
  }
  
  .pedidos__modal_footer__confirm {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    color: #555770;
    font-size: 12px;
    margin-right: 8px;
  }
  
  .pedidos__modal_footer__btn-cancelamento-voltar {
    min-width: 120px;
  }