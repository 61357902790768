@import '../../../../assets/styles/colors.scss';

.dialog-button{ 
  &__button{
    width: 40px;
    height: 40px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    background-color: $primary_color_600;
    position: fixed;
    border-radius: 100%;
    bottom: 32px;
    right: 32px;
    z-index: 2;
    &:hover {
      background-color: $primary_color_700;
    }
    &:focus {
      background-color: $primary_color_800;
    }
  }

  &__icon{
    margin-top: 5px;
    margin-left: 2px;
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
}
