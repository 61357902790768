.comunicados__page__header{
	padding-top: 24px;
}

.comunicados__page__content{
	display: grid;
	height: 100%;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr;
	grid-template-areas: 
	"filtros"
	"cards";

	&__filtros{
		grid-area: filtros;
	}

	&__cards{
		grid-area: cards;
		height: 100%;
	}
}
