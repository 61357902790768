.app-container {
    display: grid;
    margin: 0;
    padding: 0;
    height: 100vh;
    max-height: 100vh;
    width: 100%;
    grid-template-columns: 1.2fr 4fr;
    grid-template-rows: auto;
    overflow-x: hidden;
    grid-template-areas: 
      "menu content"
}

.app-container--sub-menu{
    grid-template-columns: 64px 4fr;
    grid-template-areas: "menu content";
}

.public-route-container {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template-rows: auto;
}

.app-menu {
    grid-area: menu;

    max-height: 100vh;
    min-height: 100vh;
}

.app-content {
    grid-area: content;

    max-height: 100vh;
    min-height: 100vh;
    overflow-y: auto;
}
