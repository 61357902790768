.header-historico__header{
	padding: 4px 16px;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 40px;
	background: #F7F9FC;
	font-weight: 500;
	font-size: 10px;
	line-height: 16px;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	color: #8F9BB3;
	border-bottom: 1px solid #EDF1F7;

	&__processamento{
		width: 17.52%;
		align-items: flex-start;
		padding: 0;
		margin: 0;
	}

	&__pagamento{
		width: 14.56%;
		align-items: flex-start;
	}

	&__historico{
		width: 24.20%;
		align-items: flex-start;
	}

	&__documento{
		width: 13.91%;
		align-items: flex-start;
	}

	&__valor-documento{
		width: 20.37%;
		align-items: flex-start;
	}

	&__cheque{
		width: auto;
		align-items: flex-start;
	}
}
