.product-card__container {
  width: 100%;
  height: 100%;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.product-card {
  &__title {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 0.15px;
    text-align: center;
    color: #555770;
  }

  &__text {
    padding-top: 16px;
    font-size: 12px;
    line-height: 1.33;
    text-align: center;
    color: #8f9bb3;
  }

  &__button {
    width: 100%;
    height: 40px;
    padding: 16px 0;

    display: flex;
    justify-content: center;

    & button {
      width: 200px;
    }
  }
}
