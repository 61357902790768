@import '../../../../assets/styles/colors.scss';

.menu-aside {
    background: $gradientMenu;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
    width: 64px;
}

.menu-aside__itens-container{
  display: flex;  
  flex-direction: column;
  height: 100vh;
}

.menu-aside__stellantis-logo {
    width: 100%;
    min-height: 66px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-bottom: solid rgba(255, 255, 255, 0.5) 1px;
}

.menu-aside__footer {
    margin-bottom: 0px;
    margin-top: auto;
}

.menu-aside__stellantis-logo__icon {
    width: 32px;
    height: 32px;
    cursor: initial;
}

.menu-aside-item {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        width: 24px;
        height: 24px;
        object-fit: contain;
        cursor: pointer;
    }
}

.menu-aside-notication-center{
    color: #ffffff;
    position: relative;
}

.menu-aside-notification{
    &-success{
        border-radius: 50%;
        background-color:rgb(14, 241, 71);
        width: 8px;
        height: 8px;
        position: absolute;
        right: 22px;
        top: 22px;
    }
    &-error{
        border-radius: 50%;
        background: #E42313;
        width: 8px;
        height: 8px;
        position: absolute;
        right: 22px;
        top: 22px;
    }
}
