.chat-input__container{
  width: 320px;
  height: 40px;
  border-radius: 6px;
  background-color: #f9fafc;

  font-size: 12px;
  line-height: 1.33;
  color: #d8d8d8;
}
