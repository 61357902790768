@import '../../../../assets/styles/colors.scss';

.list__container-div {
  height: 100%;
  overflow: hidden;
}

.list__inner-div {
  width: 100%;
	height: 100%;
  overflow-x: hidden;
  overflow-y: visible;
}

.list__rendered-content {
  width: 100%;
  height: 100%;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.6);
}

// Loading animation

.list__loading-dots::before, .list__loading-dots::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.list__loading-dots {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $primary_color_600;
  color: $primary_color_600;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
}

.list__loading-dots::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $primary_color_600;
  color: $primary_color_600;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.list__loading-dots::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $primary_color_600;
  color: $primary_color_600;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: $primary_color_600;
  }
  50%,
  100% {
    background-color: $primary_color_200;
  }
}

// End loading animation
