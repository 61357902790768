@import '../../../../../../../../../assets/styles/colors.scss';

.carteira-page__identificador__container__logo {
    margin: auto auto;
    height: 32px;
    width: 32px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-red{
    background-color: $error_color_100_24;
}

.icon-green {
    background-color: $terciary_color_100_24;
}

.icon-blue{
	background-color: $primary_color_100_24;
}
