.chat-popover__card-error-feedback__title{
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  color: rgba(0, 0, 0, 0.82);
  display: inline-flex;
  
  margin-top: 14px;
  margin-left: 16px;
}

.chat-popover__card-error-feedback__subtitle{
  font-size: 12px;
  line-height: 1.33;
  color: rgba(0, 0, 0, 0.6);
  margin-left: 16px;
  margin-right: 16px;
  display: inline-flex;
}

.chat-popover__card-error-feedback__button{
  width: 40px;
  height: 40px;
  border-radius: 3px;
  background-color: #00afad;
  
  margin-left: auto;
  margin-right: 16px;
  margin-top: 16px;
}

.chat-popover__card-error-feedback__button-image{
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-top: 4px;
}

.chat-popover__card-error-feedback__info-container{
  display: flex;
  flex-direction: column;
}
