.modal-limite-proposta__dados{
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    margin-left: 12px;
}
.modal-limite-proposta__dados-title{
    color: #3C414E;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.modal-limite-proposta__item-chave{
    color: #3C414E;
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: 16px;
    padding: 16px 0px 12px 0px;
}

.modal-limite-proposta__item-valor{
    color: #505669;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 24px;
    padding-bottom: 8px;
}

.modal-limite-proposta__items{
    display: flex;
}

.modal-limite-proposta__item{
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    width: 164px;
}
