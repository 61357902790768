@import 'assets/styles/colors.scss';

.sub-menu-item {
    height: 28px;
    padding: 4px 16px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;

    &--active {
        background-color: $primary_color_100_36;
    }

    &--active > .sub-menu-item-text {
        color: $primary_color_700;
    }

    &:hover {
        background-color: $primary_color_100_24;
        & > .sub-menu-item-text {
            color: $primary_color_700;
        }
    }
}

.sub-menu-item-text {
    display: block;
    color: $secundary_color_800;

    span {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.25px;
        line-height: 20px;

        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: middle;
    }
}

.sub-menu-item-icon {
    margin-right: 10px;
    vertical-align: middle;
}
