
.menu__payment-button__container {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 24px;
}

.menu__payment-button__btn {
  width: 100%;
  padding: 8px 0;

  &-btn {
    display: block;
    width: 100%;

    height: 38px;
    border-radius: 10px;

    & span {
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.25px;
      line-height: 28px;  
    }
  }
}


.menu__payment-button__text-container {
  padding: 4px 0 12px 0;
}

.menu__payment-button__text {
  font-size: 10px;
  line-height: 1.6;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}