.choice-message-list__container{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: auto;
  width: 84%;
  padding: 0px 8%;
}
.choice-message-list__item-container{
  margin-right: 8px;
  margin-top: 8px;
  display: inline-block;
}