.common__comentarios-list-row__container {
    display: grid;
    font-size: 12px;
    color: #8f9bb3;

    padding: 0px 16px 0px 0px;
    min-height: 37px;

    grid-template-columns: 0px 16px 1fr 28px;
    grid-template-rows: auto;
    column-gap: 5px;
    grid-template-areas: "line icone conteudo hora";



    &_line {
        grid-area: line;
        border-left: 1px solid #edf1f7;
        margin-left: 12px;
    }
    &_icone {
        grid-area: icone;
        display: flex;
        justify-content: center;
    }
    &_conteudo {
        grid-area: conteudo;
        display: flex;
        align-items: center;
				margin: 0px 8px 8px 0px;
    }
    &_hora {
        grid-area: hora;
        display: flex;
    }
}
