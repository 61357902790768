@import 'assets/styles/colors.scss';

.common__page-header__subtitle{
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.id-limite{
  color: $primary_color_600;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.limite-cadastro__page__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 64px;
  gap: 8px;
}

