.tooltip__carteira {
display: flex;
	opacity: 1;
	color: white;
	background: rgba(0, 0, 0, 0.7);
	border-radius: 4px;
	transition: all 0.1s ease;
	pointer-events: none;
	transform: translate(-30%, 0);
	align-items: center;
	justify-content: center;	
	padding: 4px;
	min-width: 80px;
}

.tooltip__carteira__cor{
	margin: 0 8px 0 4px;
	border-radius: 50%;
	width: 10px;
	height: 10px;
}
