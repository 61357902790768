.list-content {
  height: 684px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.list-header-fixed {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1000;
}