.relatorios__filtros__container{
	margin: 16px 16px 24px 40px;
	display: flex;

	&__concessionarias{
		margin: 1px 8px 0 0;
		width: 236px;
	}

	&__documento{
		width: 320px;
		margin: 1px 8px 0 0;
		padding-left: 16px;
		margin-top: -2px;
	}

	&__botao-filtrar{
		width: 86px;
		margin: 20px 16px 0 0;
		padding-left: 16px;
	}

	&__botao-download{
		width: 3.70%;
		margin-top: 20px;
	}

	&__loading-container{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}
	&__data{
		width: 236px;
		padding-left: 16px;
	}
}
