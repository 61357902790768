.payment-header-listing {
  &-container{
      display: grid;
      margin-left: 48px;
      margin-right: 48px;
      grid-template-columns: auto auto 220px 8px 260px;
      grid-template-rows: auto;
      grid-template-areas: "title . open-field . date-filter";
  }
  &-title{
    grid-area: title;
  }
  &-date-filter{
    grid-area: date-filter;
    margin-top: 8px;
    margin-bottom: 16px;
  }
  &-open-field-filter{
    grid-area: open-field;
    margin-top: 12px;
  }
}
