@import '../../../../assets/styles/colors.scss';

.common__btn {
  box-sizing: border-box;

  min-height: 32px;
  height: 100%;

  border-radius: 4px;
  border: none;

  position: relative;
  cursor: pointer;

  &:focus {
    outline: none;
  }


  &--full-width {
    width: 100%;
  }

  &__content {
    margin: 2px 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.25px;
    line-height: 20px;
    text-align: center;
  }
  
  &__content--loading {
    opacity: 0;
  }

  &:disabled {
    cursor: default;
  }
}

.common__btn__loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  & > div {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
      position: unset;
      top: unset;
      left: unset;
    }
  }
}

.common__btn__red {
  background-color: rgba(237, 92, 111, 0.24);
  font-weight: 500;
  color: #ed5c6f;

  &:disabled {
    cursor: default;
    color: #F6ADB7;
    background-color: rgba(237, 92, 111, 0.16);
  }
}

.common__btn__new-blue {
  background-color: $primary_color_500;
  font-weight: 500;
  color: white;
  &:hover {
    background-color: $primary_color_600;
  }
  &:focus {
    background-color: $primary_color_700;
  }
  &:disabled {
    cursor: default;
    background-color: $secundary_color_100;
    color: $secundary_color_800;
  }
}

.common__btn__dark-gray {
  background-color: $secundary_color_700;
  font-weight: 500;
  color: white;
  &:hover {
    background-color: $secundary_color_800;
  }
  &:focus {
    background-color: $secundary_color_900;
  }
  &:disabled {
    cursor: default;
    background-color:  $secundary_color_100;
    color: $secundary_color_800;
  }
}

.common__btn__dark-gray-border {
  background-color: white;
  font-weight: 500;
  border: 1px solid $secundary_color_700;
  color: $secundary_color_800;
  &:hover {
    background-color: $secundary_color_600_16;
    border: 1px solid $secundary_color_800;
  }
  &:focus {
    background-color: $secundary_color_600_32;
    border: 1px solid $secundary_color_800;
  }
}

.common__btn__gray {
  background-color:  #e6eaf2;
  color: #8F9BB3;
  font-weight: 500;
}

.common__btn__white {
  background-color: white;
  border: solid 1px #8f93a1;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);

  &:disabled {
    background-color: #e9eaf0;
    border: solid 1px #e9eaf0;
    color: #ffffff;
  }
}

.common__btn__light {
  background-color: rgba(0, 175, 173, 0.24);
  border: none;
  font-weight: 500;
  color: #00afad;

  &:disabled {
    background-color: #e9eaf0;
    border: solid 1px #e9eaf0;
    color: #ffffff;
  }
}


.common__btn__blue {
  background-color: #555770;
  font-weight: 500;
  color: white;

  &:disabled {
    cursor: default;
    color: #F6ADB7;
    background-color: #8c8eaa;
  }
}