.solicitacao-limite-page__page {
  display: grid;
  height: 100vh;

  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 
  "menu content";
}

.solicitacao-limite-page__menu {
  grid-area: menu;
  width: 240px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
}

.solicitacao-limite-page__content {
  grid-area: content;
  overflow-y: auto;
  overflow-x: auto;
}
