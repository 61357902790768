.acompanhamento__page__filters {
  grid-area: filters;
  width: 100%;
  padding: 8px 24px 16px 32px;
  display: grid;
  gap:16px;
	align-items: end;
	@media (min-width: 1080px) {
		grid-template-columns: 444px 236px 325px;
		grid-template-areas: "text status buttons";
  }
	@media (max-width: 1080px) {
		grid-template-columns: 444px 236px;
		grid-template-areas: "text status" "buttons .";
  }

  &-text {
    grid-area: text;
  }
  &-status {
    grid-area: status;
  }
  &-buttons{
		grid-area: buttons;
		display: flex;
		flex-direction: row;
		gap: 16px;

		&-filtrar{
			width: 86px;
		}
		&-solicitar{
			width: 223px;
		}
  }
}

.acompanhamento__page__filters__open {
	grid-template-columns: 444px 236px;
	grid-template-areas: "text status" "buttons .";
}
