.notifications-row {
  border-bottom: #e9eaf0 1px solid;
  margin: 3px 12px 3px 12px;
  padding: 16px 0px 16px 0px;

  display: grid;
  grid-template-columns: 32px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 
    "icon   info"
    ". action";

  &-reproved {
    &-icon {
      width: 16px;
      height: 16px;
      margin: auto auto;

      &-container {
        vertical-align: super;
        display: inline-flex;
        width: 32px;
        height: 32px;
        border-radius: 12px;
        background-color: rgba(237, 92, 111, 0.24);
      }
    }
  }

  &-aproved {
    &-icon {
      width: 16px;
      height: 16px;
      margin: auto auto;
      fill: #20b2aa;

      &-container {
        vertical-align: super;
        display: inline-flex;
        width: 32px;
        height: 32px;
        border-radius: 12px;
        background-color: rgba(0, 175, 173, 0.18);
      }
    }
  }

  &-filter {
    &-icon {
      width: 16px;
      height: 16px;
      margin: auto auto;
      fill: #5c6bc0;
  
      &-container {
        vertical-align: super;
        display: inline-flex;
        width: 32px;
        height: 32px;
        border-radius: 12px;
        background-color: #A7B8F5;
      }
    }
  }

  &-icon {
    grid-area: icon;
    display: flex;
    align-items: center;
  }
  &-info {
    grid-area: info;
    margin-left: 8px;
    font-size: 12px;
    line-height: 1.33;
    color: rgba(0, 0, 0, 0.6);

    display: grid;
    grid-template-columns: 1fr 32px;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "title          date  "
      "descricao   descricao";

    &-title {
      grid-area: title;
    }
    &-date {
      grid-area: date;
      text-align: right;
    }

    &-descricao {
      grid-area: descricao;
      display: flex;
      align-items: center;
      font-size: 13px;
      margin-top: 4px;
      line-height: 1.43;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.82);
    }
  }

  &-action {
    grid-area: action;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    cursor: pointer;

    span {
      font-family: 'Circular Std', sans-serif;
      font-weight: 500;
      font-size: 12px;
      line-height: 15.18px;
      color: rgba(48, 74, 175, 1);
    }

    .notifications-row-action-icon {
      width: 16px;
      height: 16px;
      color: rgba(48, 74, 175, 1);
    }
  }
}
