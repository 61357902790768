.notifications-page-section {
  width: 270px;
  height: 100%;
  padding-bottom: 35px;
}

.notifications-page-title {
  margin-left: 16px;
  display: flex;
  color: rgba(0, 0, 0, 0.82);
}
.notifications-page-x {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-top: 16px;
  margin-right: 10px;
  margin-left: auto;
  display: flex;
  color: rgba(0, 0, 0, 0.32);
}

.notifications-page-empty-state {
  width: 100px;
  height: 100px;
  cursor: pointer;
  margin-top: 50px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
}

.notification-body-loading {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
