@import 'assets/styles/colors.scss';

.content {
    display: flex;
    padding: 21px 28px 12px 27px;
    flex-direction: column;
    border-radius: 4px;
    font-weight: 400;
    font-family: 'Circular Std', sans-serif;
    font-size: 12px;
}

.content__row {
    display: flex;
    margin-bottom: 8px;
    justify-content: space-between;
    &__key {
        color: #555770;
    }
    &__value {
        color: #7A7C9A;
    }
    &__notify {
        color: $alert_color_400;
        display: flex;
        align-items: center;
    }
}

#details__produto[open] #details__summary-produto:after{
    transform: rotate(180deg);
}

#details__produto[open] {
    background-color: #FFFFFF;
    border-radius: 4px;
}

.circle-alert{
    margin-left: 4px;
    cursor: default;
}