.taxa_container{
	margin-bottom: 24px;
}

.taxa_periodo{
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	color: #8F9BB3;
	padding: 4px 0 8px 12px;
	border-bottom: 1px solid #EDF1F7;
	margin-bottom: 16px;
}

.taxas_tabelas{
	display: flex;
	width: 100%;
	flex-direction: row;
	gap: 16px;
}
