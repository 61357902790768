.snackbar-items__container{
  pointer-events: auto;
}

.snackbar-succes__item-container{
  min-width: 300px;
  
  padding-top: 18px;
  padding-bottom: 8px;
  margin-bottom: 20px;
  padding-left: 24px;
  padding-right: 24px;

  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  border-bottom: 4px solid #206446;
  outline: none;
  cursor: pointer;
}

.snackbar-succes__image{
  width: 36px;
  height: 24px;
  margin-top: -4px;
  padding-right: 16px;
  object-fit: contain;
}

.snackbar-error__item-container{
  min-width: 300px;
  
  padding-top: 18px;
  padding-bottom: 8px;
  margin-bottom: 20px;
  padding-left: 24px;
  padding-right: 24px;

  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  border-bottom: 4px solid #ed5c6f;
  outline: none;
  cursor: pointer;
}

.snackbar-error__image{
  width: 36px;
  height: 24px;
  margin-top: -4px;
  padding-right: 16px;
  object-fit: contain;
}


.snackbar__text{
  font-size: 14px;
  vertical-align: top;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.6);
}
