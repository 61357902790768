@import 'assets/styles/colors.scss';

.pedidos__modal__comentarios-list__content_label {
    border-left: solid 2px;
    font-size: 11px;
    height: 30px;

    display: flex;
    align-items: center;

    margin-left: 12px;
    padding-left: 16px;
    padding-top: 8px;
}

.pedidos__modal__comentarios-list-row__container {
    display: grid;
    font-size: 12px;

    grid-template-columns: 0px 16px 1fr 28px;
    grid-template-rows: auto auto auto;
    column-gap: 5px;
    grid-template-areas:
    "line icone titulo   titulo"
    "line icone data     data"
    "line icone conteudo hora";

    padding: 0px 16px 0px 0px;

    min-height: 37px;

}

.pedidos__modal__comentarios-list-row__container_line {
    grid-area: line;
    border-left: solid 2px ;
    margin-left: 12px;
}

.pedidos__modal__comentarios-list-row__container_icone {
    grid-area: icone;
    display: flex;
    justify-content: center;
}

.pedidos__modal__comentarios-list-row__container_titulo{
    grid-area: titulo;
    display: flex;
    align-items: center;

    font-size: 10px;
    line-height: 14px;
}

.pedidos__modal__comentarios-list-row__container_data{
    grid-area: data;
    display: flex;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 8px;

    color: $secundary_color_700;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
}


.pedidos__modal__comentarios-list-row__container_conteudo {
    grid-area: conteudo;
    display: flex;
    align-items: center;

    font-size: 10px;
    line-height: 16px;
}

.pedidos__modal__comentarios-list-row__container_hora {
    grid-area: hora;
    display: flex;
    align-items: center;

    font-size: 10px;
    line-height: 16px;
    color:  $secundary_color_700;
}

