.modal-taxa__tabelas{
	border: 1px solid #EDF1F7;
	margin: 0;
	width: 100%;
  height: 163px;
	
	&__header{
		display: flex;
		height: 32px;
		padding: 0 12px;
		align-items: center;
		justify-content: flex-start;
		background-color: #EDF1F7; 
		font-style: normal;
		font-weight: 500;
		font-size: 10px;
		line-height: 16px;
		letter-spacing: 1.5px;
		text-transform: uppercase;
		color: #404154;
	}

	&__title{
    	height: 16px;
		padding: 8px 56px 3px 12px;
		flex: none;
		font-style: normal;
		font-weight: 500;
		font-size: 10px;
		line-height: 16px;
		letter-spacing: 1.5px;
		text-transform: uppercase;
		color: #8F9BB3;		
	}

	&__title-unico{
		height: 16px;
		padding: 33px 56px 3px 12px;
		flex: none;
		font-style: normal;
		font-weight: 500;
		font-size: 10px;
		line-height: 16px;
		letter-spacing: 1.5px;
		text-transform: uppercase;
		color: #8F9BB3;		
	}

	&__taxa{
		padding: 3px 0 10px 12px;
		flex: 1;
		font-style: normal;
		font-weight: 450;
		font-size: 14px;
		line-height: 24px;
		color: #555770;		
	}

	&__taxa-unica{
		display: flex;
		padding: 3px 0 0 12px;
		align-items: center;
		font-style: normal;
		font-weight: 450;
		font-size: 14px;
		line-height: 24px;
		color: #555770;
	}
	&__test-drive{
		display: flex;
		height: 130px;
		min-width: 118px;
		align-items: center;
		margin-left: 10px;
		margin-right: 36px;
		font-family: CircularStd, sans-serif;
		font-style: normal;
		font-weight: 450;
		font-size: 14px;
		line-height: 18px;
		color: #555770;
	}

	&__divisor{
		border-bottom: 1px solid #EDF1F7;
	}
}
