@import 'assets/styles/colors.scss';

.statement_page__logo{
	width: 32px;
	height: 32px;
	border-radius: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.statement_modal__logo{
	width: 48px;
	height: 48px;
	border-radius: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.borda-pendencia{
	border: 1px dashed #ED5C6F;
}

.debito{
	background-color: $error_color_100_24;
}

.credito{
	background-color: $terciary_color_100_24;
	svg {
		color: $terciary_color_600;
	}
}
