.td__no-results {
  width: 100%;
  height: 100%;
  padding-top: 24px;
  // background-color: grey;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.td__no-results__image {
  height: 100%;
  width: auto;

  &-container {
    height: 180px;
    width: auto;
  }
}

.td__no-results__text-big {
  padding: 8px 0;
  text-align: center;

  font-size: 24px;
  font-weight: bold;
  line-height: 1.69;
  color: rgba(0, 0, 0, 0.52);
}

.td__no-results__text-small {
  line-height: 1.69em;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.52);
  & div {
    padding: 6px 0;
  }
}
