@import '../../../../assets/styles/colors.scss';

.loading-component{
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	.loading-component__loading-dots::before, .loading-component__loading-dots::after {
		content: '';
		display: inline-block;
		position: absolute;
		top: 0;
	}

	.loading-component__loading-dots {
		position: relative;
		width: 10px;
		height: 10px;
		border-radius: 5px;
		background-color: $primary_color_600;
		color: $primary_color_600;
		animation: dotFlashing 1s infinite linear alternate;
		animation-delay: .5s;
	}

	.loading-component__loading-dots::before {
		left: -15px;
		width: 10px;
		height: 10px;
		border-radius: 5px;
		background-color: $primary_color_600;
		color: $primary_color_600;
		animation: dotFlashing 1s infinite alternate;
		animation-delay: 0s;
	}

	.loading-component__loading-dots::after {
		left: 15px;
		width: 10px;
		height: 10px;
		border-radius: 5px;
		background-color: $primary_color_600;
		color: $primary_color_600;
		animation: dotFlashing 1s infinite alternate;
		animation-delay: 1s;
	}

	@keyframes dotFlashing {
		0% {
			background-color: $primary_color_600;
		}
		50%,
		100% {
			background-color: $primary_color_200;
		}
	}


	&__mensagem{
		font-style: normal;
		font-weight: 900;
		font-size: 20px;
		line-height: 25px;
		text-align: center;
		color: $secundary_color_700;
		margin-top: 8px;
	}

}
