@import 'assets/styles/colors.scss';

.td-cond-selector-card{
	display: flex;
  width: 100%;
  height: 334px;
  border-radius: 6px;
  border: 2px solid $secundary_color_100;
  background-color: #ffffff;
  
  &-clickable{
		display: flex;
		height: 100%;
    width: 100%;
    outline: none; 
  }

  &-selected{
    border-radius: 6px;
    border: 2px solid $primary_color_600 !important;
    background-color: $primary_color_100_24 !important; 
  }

  &-title-container{
    height: 53px;
    background-color: $primary_color_600 !important;
    text-align: center;
    display: flex;
  }

  &-title-text{
    font-size: 24px;
    font-weight: 900;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  &-content{
		&-container {
			display: flex;
			flex-direction: column;
		}

    &-header{
        &-number{
        font-size: 34px;
        font-weight: 900;
        text-align: center;
        color: rgba(0, 0, 0, 0.82);
      }
      &-text{
        font-size: 14px;
        text-align: center;
        color: rgba(0, 0, 0, 0.82);
      }
    }
    &-center{
      &-text{
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.15px;
        text-align: center;
        color: rgba(0, 0, 0, 0.6);
      }
    }
    &-offer-description{
      text-align: center;
      &-number{
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.6);
      }
      &-text{
        font-size: 10px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1.5px;
        color: rgba(0, 0, 0, 0.3);
      }
    }
    &-taxes{
      &-number{
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: 0.1px;
        text-align: center;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}

