.relatorios_filters_date-range {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding-right: 8px;
	.relatorios_filters_date-range_close-button {
		margin-top: 4px;
		padding: 12px 0 0 3px;
	}
}

