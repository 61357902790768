.error-component{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&__icon{
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__mensagem{
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: 0.15px;
		color: #555770;
	}

	&__submensagem{
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		text-align: center;
		color: #555770;
	}
}
