@import 'assets/styles/colors';

.chat-popover__popover-content-main__container {
  width: 320px;
  height: 480px;

  position: relative;
}

.chat-popover__popover-content-main__background {
  position: relative;
  width: 100%;
  height: 100%;
}

.chat-popover__popover-content-main__popover__upper-background {
  background-color: $primary_color_500;
  width: 320px;
  height: 300px;
  position: absolute;
  top: 0px;
  z-index: 0;
}

.chat-popover__popover-content-main__content {
  position: absolute;
  top: 0px;
  left:0px;
  width: 100%;
  height: 100%;
  z-index: 1;

  display: flex;
  flex-direction: column;

  grid-template-columns: 100%;
  grid-template-rows: auto auto;
  grid-template-areas: 
    "header"
    "search"
    "bottom"; 
}

.chat-popover__popover-content-main__header__container {
  grid-area: header;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.chat-popover__popover-content-main__popover-search__container {
  grid-area: search;
  display: flex;
  flex-direction: column;
  width: 288px;
  height: 156px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 28px;
  border-radius: 3px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  z-index: 1;
}

.chat-popover__popover-content-main__divider{  
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
  height: 2px;
  border-radius: 3px;
  background-color: rgba(0, 175, 173, 0.3);
  z-index: 1;
}

.chat-popover__popover-content-main__error-card-container{
  width: 288px;
  height: 72px;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  display: flex;
  z-index: 1;
}