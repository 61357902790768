.list-header {
  height: 36px;
  padding-left: 24px;
  border-bottom: 1px solid rgba(85, 85, 85, 0.08);

  &__column {
    color: #8F9BB3;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
  }

  &__id {
    @extend .list-header__column;
    grid-area: id;
    min-width: 50px;
  }
  &__produto {
    @extend .list-header__column;
    grid-area: produto;
    min-width: 232px;
  }
  &__aprovacao {
    @extend .list-header__column;
    grid-area: aprovacao;
    min-width: 80px;
  }
  &__valor {
    @extend .list-header__column;
    grid-area: valor;
    min-width: 130px;
  }
  &__vencimento {
    @extend .list-header__column;
    grid-area: vencimento;
    min-width: 90px;
  }
  &__status {
    @extend .list-header__column;
    grid-area: status;
    min-width: 200px;
  }
}