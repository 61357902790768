.modal-carteira__payment-modal__container{
	background-color: inherit;
	padding: 16px 0px 8px;
	height: calc(100% - 24px);
	position: relative;
    flex-flow: column;
    justify-content: center;
	
	&__header{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		padding-left: 24px;
		font-size: 20px;
		line-height: 23px;
		color: #555770;
		height: 48px;
	}

	&__subtitle{
		font-size: 14px;
		line-height: 24px;
		color: #8F9BB3;
	}

	&__body{
		height: calc(100% - 136px);
		padding: 16px 0;
		overflow-y: auto;
		.carteira-modal__list__container{
			height: auto;
		}
	}

	&__footer{
		border-top: 1px solid #EDF1F7;
		padding: 8px;
		position: fixed;
		bottom: 0;
		height: 56px;
		width: 100%;
		background-color: white;
	}
}
