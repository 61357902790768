.td-cond-selector-card-image{
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 8px;
  flex:1;
}
.td-cond-selector-img-text-container{
  display: flex;
  flex-flow: row;
  height: 80%;
  width: 100%;
  flex: 1;
  img{
		width: 100%;
    max-width: 300px;  
    height: auto;
  }
}
.td-cond-selector-card-content{
	display: flex;
  outline: none;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
}

.td-cond-selector-text-container{
  display: flex;
  padding: 8px;
  flex-flow: column-reverse;
  align-items: flex-start;
}
