@import '../../../../../../../../assets/styles/colors.scss';

.carteira-page__list-row__container__outer{
	margin: 0 24px;
	border-bottom: 1px solid $primary_color_100_24;
	&:hover {
		background-color: $primary_color_100_16;
		border-bottom: 1px solid $primary_color_100_56;
	}
}

.carteira-page__list-row__container {
  min-height: 48px;
  padding: 0 24px;
  cursor: pointer;

  display: grid;
  grid-template-rows: auto;
  
}

.carteira-page__list-row {
  &__item {
		color: #555770;
		font-size: 14px;
		line-height: 24px;
		width: 100%;
		display: flex;
		align-items: center;
  }

  &__checkbox {
    grid-area: checkbox;
		width: fit-content;
		height: 100%;
		align-items: center;
  }

  &__identificador {
    grid-area: documento;
		width: 100%;
  }

  &__concessionaria {
    grid-area: concessionaria;
		width: 100%;
  }

	&__vencimento{
		grid-area: vencimento;
		color: #8F9BB3;
		justify-content: right;
		width: 100%;
	}

	&__saldo{
		grid-area: saldo;
		justify-content: right;
	}

	&__status{
		grid-area: status;
		color: #8F9BB3;
		justify-content: right;
	}

}
