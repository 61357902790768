@import 'assets/styles/colors.scss';

.dashboard-floor-plan__legenda-carteiraBrand{
	font-style: normal;
	font-weight: 450;
	font-size: 12px;
	line-height: 16px;
	color: #555770;
	padding: 18px 1rem 0 0;
	min-width: 240px;

	&__item{
		display: grid;
    grid-template-areas: "color brand percentage value";
		grid-template-columns: 5% 38% 20% 46%; 
		align-items: center;
		justify-content: flex-start;
		margin: 8px;

		&__brand{
			grid-area: brand;
			display: flex;
			align-items: flex-start;
			margin-left: 8px;
			text-align: start;
			color: #555770;

			font-style: normal;
			font-weight: 450;
			font-size: 12px;
			line-height: 16px;

		}

		&__percentage{
			grid-area: percentage;
			line-height: 16px;
			margin-right: 10px;
			color: #555770;
			text-align: end;

			font-style: normal;
			font-weight: bold;
			font-size: 12px;
		}

		&__value{
			grid-area: value;
			font-weight: 450;
			font-size: 12px;
			line-height: 16px;
			color: #7A7C9A;
			text-align: end;
			text-align: center;
		}
        .color-brand{
			grid-area: color;
			border-radius: 50%;
			width: 9px;
			height: 9px;			
			margin-right: 8px;
			display: inline;
			&.color_fiat{
				background-color: $error_color_300;
			}
			&.color_jeep{
				background-color: #555770;
			}
			&.color_ram{
				background-color: $primary_color_600;
			}	
			&.color_chrysler{
				background-color: $primary_color_300;
			}	
			&.color_dodge{				
				background-color: $primary_color_200;
			}	
		}	
	}
}

.dashboard-floor-plan__legenda-carteira__detail_line {
	border: 1px solid #e4e9f2;
}
