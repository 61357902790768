.statement_page__identificador__container{
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-rows: auto;
	grid-template-areas: "icone identificacao";
	
}

.statement_page__identificador__logo{
	grid-area: icone;
}

.statement_page__identificador__dados{
	grid-area: identificacao;
	font-size: 14px;
	line-height: 24px;
	color: #555770;
	margin-left: 16px;

	span{
		font-size: 12px;
		line-height: 16px;
		color: #8F9BB3;
		display: block;
	}
}
