.common__form-input__container {
    font-size: 12px;
    color: #8f9bb3;
    width: 100%;
}
.common__form-input__container-label {
    margin-bottom: 15px;
}

.common__form-input__container-input {
    grid-area: box;
    margin-left: -8px;
    white-space:nowrap;
}

.common__form-input__container-input span {
    color: #555770;
    font-size: 14px;
    margin-left: 8px;
}

.red-asterisk {
    color: red;
}
