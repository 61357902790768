@import 'assets/styles/colors';

.choice-message__container-unselected{
  display: inline-block;
  outline: none;
  height: auto;
  width: auto; 
  cursor: pointer;

  padding: 4px 8px;

  border-radius: 11.5px;
  border: solid 1px $primary_color_500;
  &-answered{
    cursor: default;
  }
  &-not-answered{
    cursor: pointer;
  }
  &-not-answered:hover{
    background-color: $primary_color_100_24;
  }
}

.choice-message__text-unselected{
  font-size: 12px;
  color: $primary_color_500;
  white-space: nowrap;
  display: inline-block;
}

.choice-message__container-selected{
  display: inline-block;
  height: auto;
  width: auto; 
  outline: none;
  cursor: default;

  padding: 4px 8px;

  border-radius: 11.5px;
  border: solid 1px $primary_color_700;
  background-color: $primary_color_100;
}

.choice-message__text-selected{
  font-size: 12px;
  color: $primary_color_700;
  white-space: nowrap;
  display: inline-block;
}
