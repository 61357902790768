.popover-header__container{
  grid-area: header;
  display: flex;
  align-items: center;

  background-color: #00afad;
  color: white;
}
.popover-header__icon {
  width: 24px;
  height: 32px;
  margin-left: 8px;
  padding-left: 8px;
  margin-right: 8px;
  border-radius: 6px;

  display: flex;
  align-items: center;

  cursor: pointer;

  &:focus { outline: none; } 
}

.popover-header__title{
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  letter-spacing: 0.1px;
  color: #ffffff;
}