@import 'assets/styles/colors.scss';

.statement_page__list-row__container__outer__with_hover{
	padding: 0 24px;
	border-bottom: 1px solid $primary_color_100_24;
	&:hover {
		background-color: $primary_color_100_16;
	}

	.clickable {
		cursor: pointer;
	}
}

.statement_page__list-row__container__outer{
	padding: 0 24px;
	border-bottom: 1px solid $primary_color_100_24;
}

.selected{
	background: $primary_color_100_36;
	border-radius: 4px;
}

.statement_page__list-row__container{
	min-height: 48px;
	display: grid;
	padding: 0 24px;
	grid-template-rows: auto;
	grid-template-columns: 60% 15% 25%;
	grid-template-areas: "transacao data valor";
}

.statement_page__list-row{
	&__item{
		font-size: 14px;
		line-height: 24px;
		color: $secundary_color_700;
		font-weight: normal;

		width: 100%;
		display: flex;
		align-items: flex-end;
	}

	&__identificacao{
		grid-area: transacao;
		justify-content: flex-start;
	}

	&__data{
		grid-area: data;
		span{
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&__valor{
		grid-area: valor;
		justify-content: flex-end;
		font-weight: bold;
	}
}
