.iof-detail__container {
  width: 94%;
  padding: 8px 0 8px 16px;

  display: grid;
  grid-template-columns: 32px 1fr;
  grid-template-rows: 24px 16px;
  
  //cursor: pointer;

  grid-template-areas:
    " icon   name          "
    " icon   description   ";
}
.iof-detail-title{
  display: inline-flex;
  height: 16px;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.6;
  color: rgba(0, 0, 0, 0.6);
}

.iof-detail-name{
  grid-area: name;
	font-size: 12px;
	line-height: 12px;
	color: #555770;
	margin-left: 16px;
	display: flex;
	flex-direction: row;
	align-self: center;
}

.iof-detail-description-container{
	grid-area: description;
  margin-left: 16px;

  font-size: 12px;
  line-height: 16px;

  color: #8F9BB3;
}

.iof-detail-raiz-cnpj{
	background-color: #8F9BB3;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  display: inline-flex;
  margin: 2px 4px;
}

.iof-detail-matriz{
	background-color: #8F9BB3;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  display: inline-flex;
  margin: 2px 4px;
}

.iof-detail-item-divider{
	background-color: #8F9BB3;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  display: inline-flex;
  margin: 2px 4px;
}

.iof-detail-icon{
	grid-area: icon;
  margin-top: 4px;
}

  .iof-detail-brand-image-fiat{
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 24px;
    margin-right: 36px;
    width: 32px;
    height: 32px;
    border-radius: 12px;
    box-shadow: 0 2px 8px 0 #e9eaf0;
    background-color: #ffffff;
    display: inline-flex;
    justify-items: center;
    justify-content: center;
    align-items: center;
    img{
      height: 13px;
      width: 17px;
    }
  }

  .iof-detail-brand-image-jeep{
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 24px;
    margin-right: 36px;
    width: 32px;
    height: 32px;
    border-radius: 12px;
    box-shadow: 0 2px 8px 0 #e9eaf0;
    background-color: #ffffff;
    display: inline-flex;
    justify-items: center;
    justify-content: center;
    align-items: center;
    img{
      height: 8px;
      width: 22px;
    }
  }

	.iof-detail-brand-image{
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 24px;
    margin-right: 36px;
    width: 32px;
    height: 32px;
    border-radius: 12px;
    display: inline-flex;
    justify-items: center;
    justify-content: center;
    align-items: center;
  }
