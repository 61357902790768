@import '../../../../../../../../assets/styles/colors.scss';

.carteira-page__inv-page {
  &-export-button{
      width: 100%;
      height: 38px;
      border-top: 12px;
		  padding: 11px 16px;
      background: $primary_color_500;
  }

  &-popper-container{
    background-color: #ffffff;
  }

  &-export-container{
    margin-top: auto;
    margin-left: auto;
    height: auto;
  }

  &-popover-content{
    width: 479px;
    height: fit-content;
    margin-top: 12px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 40px;
    background: white;
  }

  &-popover-text{
    font-size: 18px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.6);
  }

  &-export-text-container{
    margin-bottom: 32px;
  }

  &-export-buttons{
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
  }

  &-export-button-download-XLSX{
    width: 127px;
    height: 38px;
    margin-left: 12px;
  }

  &-export-button-download-CSV{
    width: 127px;
    height: 38px;
    margin-left: 12px;
  }

  &-export-button-cancel{
    width: 91px;
    height: 38px;
    border: rgba(0, 0, 0, 0.02);
  }

  &-export-square{
    position: absolute;
    right: 75px;
    top: 8px;
    height: 6px;
    width: 6px;
    border-width: 0 1px 1px 0;
    background-color: #ffffff;
    border: solid 1px rgba(0, 0, 0, 0.15);
    transform:rotate(45deg);
    border-bottom:  solid transparent;
    border-right: solid transparent;
  }
  
  &-export-legal-text{
    font-size: 12px;
    line-height: 1.33;
    color: rgba(0, 0, 0, 0.6);
  }
  
  &-export-legal-text-container{
    margin-bottom: 32px;
  }
}

.MuiFormControlLabel-label{
  height: 15px;
  opacity: 0.6;
  font-size: 12px;
  vertical-align: -2px;
  font-weight: 500;
  letter-spacing: 0.21px;
  color: #3a3d42;
}
.MuiFormControlLabel-root{
  display: inline-block !important;
}
.box:hover .inner_box {
  box-shadow: none;
}
.MuiPopover-paper{
  box-shadow: 1px 1px 1px transparent;
}
