@import '../../../../assets/styles/colors.scss';

.text-message__user-content{
  text-align: end;
 }

.text-message__user-container{
  text-align: end;
  display: inline-flex;
  flex-direction: row-reverse;
 }
 
 .text-message__fidis-container{
   display: inline-flex;
}

.text-message__icon {
  width: 22px;
  height: 22px;
  margin: 0px 4px;

  border-radius: 100%;
  background-color: $primary_color_700;
  overflow: hidden;
  cursor: initial;

  & svg {
    max-width: 100%;
    max-height: 100%;
  }
}

.text-message__box {
  padding: 8px;
  margin: 0px 4px;
  width: 77%;
  height: auto;
  border-radius: 3px;

  text-align: left;
  font-size: 12px;
  line-height: 1.33;
  color: rgba(0, 0, 0, 0.6);
  overflow-wrap: anywhere;

  &--white {
    border: solid 1px #e9eaf0;
    background-color: #f9fafc;
    text-align: initial;
  }

  &--green {
    border: solid 1px $primary_color_700;
    background-color: $primary_color_100_24;
    color: $secundary_color_800;
  }
}
