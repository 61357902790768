@import 'assets/styles/colors.scss';

.user_data {
	grid-template-columns: 14% 86%;
  display: grid;
  grid-template-areas:
    "icon user";
	
	&__icon {
		position: relative;
		grid-area: icon;
		background-color: $primary_color_400;
		display: flex;
		justify-content: center;
		height: 44px;
		width: 44px;
		border-radius: 50%;

	}

	&__user {
		grid-area: user;
		display: grid;
		margin-left: 8px;
  	grid-template-areas:
    "name"
		"email";
		&_name {
			grid-area: name;
			font-style: normal;
			font-weight: 450;
			font-size: 14px;
			line-height: 24px;

			color: #555770;
		}
		&_email {
			grid-area: email;
			font-style: normal;
			font-weight: 450;
			font-size: 12px;
			line-height: 16px;
			/* identical to box height, or 133% */


			color: #8F9BB3;
		}
	}
	.user_data__icon-initials{
		display: flex;
    top: 20%;
    left: 25%;
	}
	
	.user_data__icon-initials-text{
		position: absolute;
		font-size: 16px;
		color: white;
		left: 50%;
		top:50%;
		transform: translate(-50%,-50%);
	}

	
}
