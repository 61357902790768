.page-error-boundary-content__container {
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.page-error-boundary-content__content {
  text-align: center;
  color: rgba(0, 0, 0, 0.52);
  line-height: 1.69;
  font-size: 16px;

  h1 {
    font-size: 24px;
  }
}