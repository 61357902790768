.detalheMovimentacao__title{
	margin-top: 16px;
	font-size: 14px;
	line-height: 24px;

	text-align: center;
	color: #555770;
}

.detalheMovimentacao__date_time{
	margin-bottom: 16px;
	line-height: 24px;

	text-align: center;
	color: #8F9BB3;
}

.detalheMovimentacao__divider{
	margin-top: 12px;
	margin-bottom: 12px;
}

.detalheMovimentacao__pendency {
	padding-top: 40px;
}

.detalheMovimentacao__pendency_code {
	font-size: 12px;
	text-align: center;

	color: #8F9BB3;
}

.detalheMovimentacao__pendency_value {
	font-size: 24px;
	line-height: 28px;
	text-align: center;

	color: #555770;
}

.detalheMovimentacao__pendency_credit {
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	color: #8F9BB3;
}

.detalheMovimentacao__payment_date {
	display: inline-flex;
	width: -webkit-fill-available;
	margin-left: 16px;
	font-size: 12px;
	line-height: 16px;
	color: #555770;
}

.detalheMovimentacao__duplicata {
	display: inline-flex;

	width: -webkit-fill-available;
	margin-left: 16px;
	font-size: 12px;
	line-height: 16px;

	color: #555770;
	}

.detalhe-movimentacao__container {
	background-color: rgba(246, 248, 250, 0.5);
	height: calc(100% - 16px);

	width: auto;
  padding: 0px 0 16px;

	display: grid;
	grid-template-rows: auto 1fr 56px;
	grid-template-areas:
		"card"
		"messages"
		"footer"
}

.detalhe-movimentacao__modal__detalhes {
	grid-area: "card";
	position: relative;
	padding: 0 24px;
}

.detalhe-movimentacao__modal__mensagem {
	grid-area: "messages";
	height: 100%;
	padding: 0 24px;
	&_timeline {
		height: 100%;
	}
}

.detalhe-movimentacao__modal__footer{
	grid-area: "footer";
	display: flex;
	align-items: center;
	justify-content: center;
	border-top: 1px solid rgba(85, 87, 112, 0.08);
}
