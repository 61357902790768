.invoices-count-desc {
  height: 28px;
  font-size: 16px;
  line-height: 28px;

  font-weight: 300;
  color: rgba(0,0,0,0.6);
}

.invoices-count-value {
  height: 24px;
  font-size: 16px;
  line-height: 24px;

  color: rgba(0,0,0,0.82);
  font-weight: bold;
  letter-spacing: 0.15px;
}
