.duplicataValue__container {
    margin: auto;
    margin-right: 16px;
    white-space: nowrap;
}
.duplicataValue__value-main {
    color: rgba(0,0,0,0.82);
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0.1px;
	line-height: 24px;
    text-align: right;
    margin: auto auto;
}

.duplicataValue__value-secondary {
    height: 16px;
    color: rgba(0,0,0,0.6);
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    text-align: right;
    margin: auto auto;
}
