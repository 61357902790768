/* Container */
.payment-row-section {
    cursor: pointer;
    padding-bottom: 16px;
    padding-top: 16px;
    margin: 0px 48px;
    border-bottom: 1px solid #ebebeb;
    border-radius: 0.5px;
    transition: 0.4s;
    display: grid;
    grid-template-columns: 48px 48px auto 0.8fr auto 0.2fr 8em;
    grid-template-rows: auto;
    grid-template-areas: "checkbox icon-section description . date . value";
    outline: none;
}

.payment-row-checkbox {
    grid-area: checkbox;
}

.payment-row-icon {
    grid-area: icon-section;
}

.payment-row-desc {
    grid-area: description;
    margin-left: 16px;
    min-width: 240px;
}

.payment-row-date {
    grid-area: date;
}

.payment-row-value {
    grid-area: value;
}

.payment-row-section:hover {
    background-color: rgba(236, 236, 236, 0.35);
}

.payment-row-activated {
    background-color: rgba(0, 175, 173, 0.05);
}
