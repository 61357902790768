.emoji-button__container-unselected{
  display: inline-block;
  outline: none;
  height: 100%;
  width: 100%; 
  cursor: pointer;
  &-answered{
    cursor: default;
  	-webkit-filter: grayscale(100%);
	  filter: grayscale(100%);
    opacity : 0.4;
  }
  &-not-answered{
    cursor: pointer;
  }
}

.chat__emoji-btn__container-active {
  cursor: pointer;
}

.chat__emoji-btn-active__emoji {
  cursor: pointer;
  outline: none;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}

.chat__emoji-btn-answered__emoji {
  &--selected {
    cursor: default;
  }

  &--not-selected {
    cursor: default;

    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity : 0.4;
  }
}