@import 'assets/styles/colors.scss';

.dashboard__card-resumo {
	height: calc(100% - 24px);
	min-width: 130px;
	background: $primary_color_100_56;
	color: $secundary_color_900;
	border-radius: 4px;
	padding: 12px 10px;
	font-size: 14px;
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: 35px 75px 35px;
	grid-template-areas:
			"header"
			"content";

	&__header {
			grid-area: header;
			color: $secundary_color_900;
			display: grid;
			grid-template-columns: 1fr 20px;
			grid-template-rows: auto;
			grid-template-areas: "value icon";

			&_value {
					grid-area: value;
					font-weight: bold;
					font-size: 28px;
					line-height: 35px;
			}
			
			&_icon {
					grid-area: icon;
					font-size: 20px;
					display: flex;
					align-items: center;
			}
	}

	&__content {
			margin-top: 8px;
			grid-area: content;
			display: flex;
			flex-direction: row;
			font-weight: 500;
			line-height: 18px;
			width: 100%;

			span {
				padding-top: 4px;
				font-size: 8px;
			}
	}
	
	&__footer {
			grid-area: footer;
			font-size: 10px;
			line-height: 12px;
			padding-top: 7px;
			border-top: 1px solid #404154;
	}
}

.dashboard__card-resumo__container__error {
	grid-area: content;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	&_icon {
			margin-bottom: 4px;
			color: $error_color_300;
	}
	&_message {
			width: 100px;
	}
}
.dashboard__card-resumo__container__loading {
	grid-area: content;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 14px;
}

.dashboard__card-resumo__data-row {
	display: grid;
	margin-top: 5px;
	grid-template-columns: 1fr 35px 35px;
	grid-template-rows: auto;
	grid-template-areas: "label percent  quant";

	&_label {
		grid-area: label;
		white-space: nowrap;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;

		&-tooltip {
				visibility: hidden;
				width: 120px;
				background-color: #2A2B38;
				color: #fff;
				text-align: center;
				border-radius: 6px;
				padding: 5px 0;

				position: absolute;
				z-index: 1;
			}
	}
	&_label:hover > &_label-tooltip {
			visibility: visible;
	}


	&_percent {
			grid-area: percent;
			display: flex;
			justify-content: flex-end;
	}
	&_value {
			grid-area: quant;
			color: #c5cee0;
			display: flex;
			justify-content: flex-end;
	}
}

.dashboard__card-resumo__data-row_label-tooltip:hover{
	display: block;
}

 .dashboard__card-resumo__content__messenge {
 	font-style: normal;
 	font-weight: 450;
 	font-size: 12px;
 	line-height: 16px;
 	display: flex;
 	align-items: center;
 	color: $secundary_color_900;
 }

