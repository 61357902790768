@import '../../../assets/styles/colors.scss';

.session-modal-line {
  display: flex;
  justify-content: center;
  align-items: center;
    
    &-block{
      margin-bottom: 8px;
    }
  
    &-smaller{
      display: flex;
      justify-content: center;
      font-size: 20px;
      font-weight: 900;
      text-align: center;
      color: rgba(0, 0, 0, 0.82);
    }
  	&-bigger{
      display: flex;
      justify-content: center;
      font-weight: 500;
      font-size: 20px;
      text-align: center;
      color: rgba(0, 0, 0, 0.82);
    }
  
}
.session-modal-image{
  margin-bottom: 24px;
}

#session-modal-close {
  svg {
      position: absolute;
      right: 0;
      margin: 21px;
      width: 24px;
      height: 24px;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.5);
  }
}
.session-modal-button{
  background: $primary_color_700;
  margin-top: 32px;
  margin-right: auto;
  margin-left: auto;

  width: 179px;
  height: 38px;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.25px;
  text-align: center;
  color: #ffffff;
}