.duplicataCurrency__container {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  svg {
    margin-right: 0.5em;
    margin-bottom: 0.2em;
    width: 1em;
    height: 1em;
  }
}

.duplicataCurrency__container--red {
  color: #ED5C6F;
}

.duplicataCurrency__container--grey {
  color:rgba(0,0,0,0.6);
}

.duplicataCurrency__value--lineThrough {
  text-decoration: line-through;
}