.pedidos__modal__sidebar {
  background-color: rgba(246, 248, 250, 0.5);
  height: 100%;

  display: grid;
  grid-template-rows: auto minmax(0, 1fr) 56px;
  grid-template-areas:
    "card"
    "messages"
    "footer";
}

.pedidos__modal__detalhes {
  grid-area: card;
  position: relative;
}

.pedidos__modal__mensagem {
  grid-area: messages;
  margin-top: 14px;
  border-top: 1px solid rgba(85, 87, 112, 0.1);
  &_timeline {
    height: 100%;
    padding-left: 24px;
  }
}

.pedidos__modal__footer {
  grid-area: footer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:8px 24px 8px 24px;
  border-top: 1px solid rgba(85, 87, 112, 0.1);
}
