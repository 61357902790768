.mensagem-rede__card__content {
    display: grid;
    row-gap: 10px;
    color: #555770;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
    grid-template-areas:
        "icon"
        "title"
        "content";

    &_icon {
        grid-area: icon;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &_title {
        grid-area: title;
        font-weight: 900;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
    }
    &_content {
        grid-area: content;
        font-size: 14px;
        line-height: 24px;
    }
}
