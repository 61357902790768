.historico{
  width: 420px;
  margin-bottom: 24px;
}

.historico-title{
  color: #2A2B38;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-left: 12px;
}