.back-button {
    background-color: inherit;
    height: 100%;
    width: 100%;
    color: inherit;
    font-size: inherit;
    outline: none;
    white-space: nowrap;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.back-button__close-icon {
    width: 24px;
    height: 24px;
    margin-left: 4px;
}

.back-button__close-text {
    margin-top: -2px;
    font-size: 10px;
}
