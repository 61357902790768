@import 'assets/styles/colors.scss';

.card-vencimento__container-comun{
	display: flex;
	flex-direction: column;
	background: $primary_color_100_56;
	border-radius: 4px;
	min-width: 244px;
	height: 200px;
	color: $secundary_color_900;

	.card-vencimento__quantidades{
		font-weight: bold;
		font-size: 28px;
		line-height: 28px;
		margin: 16px 16px 0;
		align-self: flex-start;
	}

	.card-vencimento__label{
		font-size: 12px;
		line-height: 14px;
		margin: 8px 16px 0;
		align-self: flex-start;
	}

	.card-vencimento__valor{
		font-weight: bold;
		font-size: 14px;
		line-height: 16px;
		margin: 8px 16px 0;
		align-self: flex-start;
	}

	.card-vencimento__valor-acumulado{
		font-weight: bold;
		font-size: 12px;
		line-height: 14px;
		margin: 42px 16px 8px;
		align-self: flex-start;
	}

	.card-vencimento__footer{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		height: 42px;
		background: $primary_color_600;
		border-radius: 0px 0px 4px 4px;
		padding: 12px 16px;
	}

}

.card-vencimento__container-vencida{
	display: flex;
	flex-direction: column;
	background: $error_color_100_16;
	border-radius: 4px;
	height: 200px;
	color: $error_color_500;

	.card-vencimento__quantidades{
		font-weight: bold;
		font-size: 28px;
		line-height: 28px;
		margin: 16px 16px 0;
		align-self: flex-start;
	}

	.card-vencimento__label{
		font-size: 12px;
		line-height: 14px;
		margin: 8px 16px 0;
		align-self: flex-start;
	}

	.card-vencimento__valor{
		font-weight: bold;
		font-size: 14px;
		line-height: 16px;
		margin: 8px 16px 0;
		align-self: flex-start;
	}

	.card-vencimento__valor-acumulado{
		font-size: 12px;
		line-height: 14px;
		margin: 42px 16px 8px;
		align-self: flex-start;
	}

	.card-vencimento__footer{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		height: 42px;
		background: $error_color_400;
		border-radius: 0px 0px 4px 4px;
		padding: 12px 16px;
	}

}
