.acompanhamento__detalhe__cabecalho{
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: 1fr auto;
	grid-template-areas: 
	"tipo"
	"date";
	margin: 8px 0;

	&__tipo{
		grid-area: tipo;
		justify-self: center;
		font-size: 14px;
		line-height: 24px;
		color: #555770;
	}

	&__date{
		grid-area: date;
		justify-self: center;
		font-size: 14px;
		line-height: 24px;
		color: #8F9BB3;
	}
}
