.invoices-total-upper-part {
  height: 24px;
  vertical-align: center;
  white-space: nowrap;
}

.invoices-total-desc {
  height: 28px;
  font-size: 16px;
  line-height: 28px;

  font-weight: 300;
  color: rgba(0,0,0,0.6);
}

.invoices-total-value {
  height: 24px;
  font-size: 16px;
  line-height: 24px;

  color: rgba(0,0,0,0.82);
  font-weight: bold;
  letter-spacing: 0.15px;
}

.invoices-total-no-balance {
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  color: #ed5c6f;
  vertical-align: middle;

  svg {
    padding-right: 4px;
  }

  svg path {
    fill: #ed5c6f;
  }
}