.timeline__summary{
    margin: 12px 0px 0px 0px;
    ::before{
        display: none;
    }
}

.timeline__details-open{
    margin: 0px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    ::before{
        display: none;
    }
}

.timeline__summary-content{
    display: flex;
    flex-direction: column;
}

.timeline-container {
    max-height: 300px; 
    overflow-y: auto;
  }

.timeline__summary-content-bold-title{
    font-family: 'Circular Std', sans-serif;
    color: #1B2961;
    font-size: 12px;
    font-style: normal;
    font-weight: 900;
    line-height: 16px;
}

.timeline__summary-content-bold-subtitle{
    font-family: 'Circular Std', sans-serif;
    color: #1B2961;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.timeline__summary-content-title{
    font-family: 'Circular Std', sans-serif;
    color: #505669;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.details__container{
    min-width: 282px;
}

