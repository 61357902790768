.login-redirect__container{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	font-weight: 900;
	font-size: 40px;
	line-height: 51px;
	color: #555770;
	background-color: transparent;

	&__mensagem{
		font-weight: 900;
		font-size: 20px;
		line-height: 25px;
		color: #7A7C9A;
		margin-top: 8px;
	}

	&__loading{
		margin-top: 24px;
	}
}
