@import '../../../assets/styles/colors.scss';

.DayPickerNavigation_button {
  background: rgba(255, 255, 255, 0.24);
  border: none;
}

.DayPickerNavigation_button:hover {
  background: $primary_color_100_56;
  border-radius: 22px;
  border: none;
}

.CalendarDay {
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  font-family: CircularStd, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  width: 46px;
  height: 37px;
}

.CalendarDay__selected {
  background-color: $primary_color_600;
  border: 1px double $primary_color_600;
  color: #FFFFFF;
  border-radius: 4px;
}

.CalendarDay__selected:hover {
  border-radius: 4px;
  background-color: $primary_color_600;
  color: $secundary_color_800;
}

.CalendarDay__hovered_span{
  background: $primary_color_100_48;
  color: $secundary_color_800;
}

.CalendarDay__hovered_span:hover{
  background: $primary_color_100_24;
  -webkit-box-shadow:inset 0px 0px 0px 1px $primary_color_500 !important;
  -moz-box-shadow:inset 0px 0px 0px 1px $primary_color_500 !important;
  box-shadow:inset 0px 0px 0px 1px $primary_color_500 !important;
  font-weight: 700;
  border-radius: 4px;
}

.CalendarDay__default {
    border: none;
    outline: none;
    &:active {
      border: none;
    }
}

.CalendarDay__default:hover {
  border: none;
  background-color: $primary_color_100_24;
  -webkit-box-shadow:inset 0px 0px 0px 1px $primary_color_500 !important;
  -moz-box-shadow:inset 0px 0px 0px 1px $primary_color_500 !important;
  box-shadow:inset 0px 0px 0px 1px $primary_color_500 !important;
  color: $secundary_color_800;
  border-radius: 4px;
}

.CalendarMonth_caption {
  text-align: center;
  padding-top: 22px;
  padding-bottom: 37px;
  caption-side: top;
  caption-side: initial;
  font-family: CircularStd, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: $secundary_color_800;
}

.DayPicker__withBorder {
  box-shadow: none;
  border-radius: none;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  display: none;
}

.DayPickerNavigation_svg__horizontal {
  height: 19px;
  width: 19px;
  fill: $primary_color_600;
  display: block;
}

.DayPicker_weekHeader_ul {
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
  padding-right: 0;
  .DayPicker_weekHeader_li {
    display: inline-block;
    text-align: center;

    small{
      font-family: CircularStd, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 450;
      color: $primary_color_600;
    }
  }
}

.DayPicker_transitionContainer__horizontal {
  min-width: 260px;
  height: 314px !important;
}

.date-range-picker__container {
  height: auto;
  margin-top: 1px;
	width: fit-content;
	// border: none;
}

.date-range-picker__xicon:not(:empty) {
  margin: 8px 0px 5px 5px;
	align-self: center;
}

.date-range-picker__x-and-input-container {
  display: inline-flex;
	margin-left: 4px;
	height: auto;
  align-self: center;
}

.date-range-picker__tittle {
  font-size: 12px;
  line-height: 1.33;
  color: $secundary_color_800;
  flex-grow: 0;
  flex-basis: 50%;
  margin-bottom: 4px;
  margin-left: 13px;
}

.date-range-picker__input-container {
  background-color: hsla(219, 35%, 92%, 0);
}

.border {
  height: 40px;
  min-width: fit-content;
  background-color: hsla(219, 35%, 92%, 0.24) !important;
  border-radius: 4px;
	border: none;
  position: relative;
  display: inline-block;
	padding-left: 0;
}

.DateRangePickerInput {
  border-radius: 4px;
  background-color: inherit;
  font-size: 14px;
  font-weight: 300;
  height: 40px;
  display: flex;
  align-items: center;

	.DateInput_input{
		background-color: rgba(228, 233, 242, 0.24);
		color: #8F9BB3;
	}

	.DateInput_input:disabled{
		background-color: #FFFFFF;
		color: #8F9BB3;
	}
}

.DateRangePickerInput__disabled{
	background-color: #FFFFFF;
}

.DateRangePickerInput__withBorder {
  border: solid 1px rgba(85, 87, 112, 1);
}

.DateRangePickerInput_arrow {
  color: rgba(0, 0, 0, 0.13);
  font-size: 20px;
}

.DateRangePicker * {
  box-sizing: border-box;
}

.DateRangePickerInput_calendarIcon {
  color: #555770;
  outline: none;
  display: inline-block;
  cursor: pointer;
  margin: 5px 5px 0 10px;
}

.DateRangePickerInput_calendarIcon:disabled{
	cursor: default;
}

.DateInput__disabled {
  background: #FFFFFF;
  color: #555770;
}

.DateInput_input__disabled {
  background: #FFFFFF;
  font-style: inherit;
}

.CalendarDay__selected_span {
  background: $primary_color_100_48;
  color: $secundary_color_800;
}
