.td-container-conditions{
	position: relative;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 16px;
	grid-template-areas: "a b c"
                	     "A A A";
}
.td-avista{

	&-grid{
	display: flex;
	flex-direction: column;
  height: 100%;
	cursor: pointer;
	outline: none;
	
		&-content{
			margin: auto 0;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}

	&-row{
			display: flex;
			flex-direction: row;
			height: 100%;
			cursor: pointer;
			outline: none;
		&-content{
			display: flex;
			flex-direction: row;
		}
	}
}


.td-cond-selector-card{
  height: 334px;
  border-radius: 5.4px;
  border: solid 1.8px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  flex: 1;
	
	&-clickable{
		cursor: pointer;
		display: flex;
		flex-direction: column;
	}

  &-title-text{
		margin: auto;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }

  &-content{
		&-container{
			height: 281px;
			display: flex;
			flex-direction: column;
		}

		&-item{
			display: flex;
			flex-direction: column;
			padding-top: 8px;
			align-items: center;

			&:nth-child(4){
				flex-direction: row;
				& .td-cond-selector-card-content-offer-description{
					&:nth-child(1){
						margin-right: auto;
						margin-left: 32px;
					}
					&:nth-child(2){
						margin-right: 32px;
						margin-left: auto;
					}
				}
			}
		}

    &-header{
			display: flex;
			flex-direction: column;

        &-number{
        font-size: 34px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: rgba(0, 0, 0, 0.82);
      }
      &-text{
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: rgba(0, 0, 0, 0.82);
      }
		}

    &-center{
      &-text{
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.15px;
        text-align: center;
        color: rgba(0, 0, 0, 0.6);
      }
		}

		&-offer {
			&-container{
				display: flex;
				flex-direction: row;
			}
		
			&-description{
				display: flex;
				flex-direction: column;
				text-align: center;

				&-text{
					font-size: 10px;
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.6;
					letter-spacing: 1.5px;
					color: rgba(0, 0, 0, 0.3);
				}

				&-number{
					font-size: 16px;
					font-weight: bold;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.5;
					letter-spacing: 0.15px;
					color: rgba(0, 0, 0, 0.6);
				}

				&-note{
					font-size: 10px;
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.6;
					color: rgba(0, 0, 0, 0.6);
				}
			}
		}
	
    &-taxes{
      &-number{
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: 0.1px;
        text-align: center;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}
