.tooltip__brand__valor {
display: flex;
	opacity: 1;
	color: white;
	background: rgba(0, 0, 0, 0.7);
	border-radius: 4px;
	-webkit-transition: all 0.1s ease;
	transition: all 0.1s ease;
	pointer-events: none;
	-webkit-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
	align-items: center;
	justify-content: center;	
	padding: 0.8rem;
}

.tooltip__brand__cor{
	margin: 0 0.8rem 0 0.4rem;
	border-radius: 50%;
	width: 0.8rem;
	height: 0.8rem;
}


.fiat {
	background-color:#ED5C6F;
}

.jeep {
	background-color: #555770 ;
}

.dodge {
	background-color: #DE1932;
}

.ram {
	background-color: #15161C;
}

.chrysler {
	background-color: #004AB9
}

.default {
	background-color: #ED5C6F;
}

