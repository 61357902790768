.test-drive__modal__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin: 0px 37px 22px 37px;
    height: 55vh;
}

.test-drive__modal__container__header {
    margin-bottom: 20px;
    text-align: center;
    &_title {
        font-size: 24px;
        line-height: 28px;
        font-weight: 900;
        color: #555770;
    }

    &_subtitle {
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #8f9bb3;
    }
}


.test-drive__modal__container__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.test-drive__modal__container__content-upload {
    border: 1px dashed rgba(85, 87, 112, 0.48);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

    line-height: 16px;
    color: #555770;

    &_icon {
        margin-bottom: 10px;
    }
    &_title {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 4px;
    }
    &_subtitle {
        font-size: 12px;
    }
}

.test-drive__upload_enbaled {
    background: rgba(85, 87, 112, 0.08);
    cursor: pointer;
}

.test-drive__upload_disabled {
    background: gray;
    cursor: default;
}

.test-drive__modal__container__content_preview {
    margin-top: 20px;
    width: 100%;
    align-self: center;
}

.test-drive__modal__container__footer {
    position: absolute;
    bottom: 12px;
}
