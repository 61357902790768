.pedidos__card-resumo__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  position: relative;
  margin:0 24px 0 24px;
}

.pedidos__card-resumo__card {
  width: 100%;
  min-height: 200px;

  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(85, 87, 112, 0.16), 0 0 2px 0 rgba(85, 87, 112, 0.08);

  color: #8f9bb3;
  font-size: 12px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &-lower {
    border-top: solid 1px rgba(85, 87, 112, 0.08);
    padding: 8px 16px;
  }
}

.pedidos__card-resumo__model {
  text-align: center;
  padding: 32px 16px 12px;

  grid-template-columns: 1fr;
  grid-template-rows: 50px 15px;
  display: grid;
  grid-template-areas:
    "desc" 
    "year";

  &-desc {
    grid-area: desc;
    color: #555770;
    font-size: 18px;
    font-weight: 900;
    display: flex;

    align-items: center;
    justify-content:center;
    text-align: center;

    text-transform: capitalize;
  }

  &-year {
    grid-area: year;
    line-height: 1.33;
    font-size: 12px;
  }
}

.pedidos__card-resumo__data-row {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;

  &-label {
    color: #555770;
  }

  &-value {
    max-width: 70%;
    text-align: right;
  }
}

.pedidos__card-resumo__header {
  height: 100px;
  padding-top: 24px;

  font-size: 14px;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &-produto {
    color: #555770;
    font-weight: bold;
  }

  &-data {
    padding: 4px 0;
    color: #8f9bb3;
  }
}

.pedidos__card-resumo__img {
  position: absolute;
  height: 100px;
  top: 52px;

  & img {
    max-width: 100%;
    max-height: 100%;
  }
}

.pedidos__card-resumo__excecao{
  padding-top: 16px;
  width: 100%;
  align-self: flex-start;
}
