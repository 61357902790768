@import 'assets/styles/colors.scss';

.main{
    display: top;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}

.historico-drawer{
    margin-top: 16px;
    margin-left: 5px;
    margin-right: 15px;
}

.observacoes{
    margin: 6px 15px 6px 5px;
}

.detailTitle{
    display:flex;
    &__title{
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 12px;
        font-size: 16px;
    }
}

.details{
    margin: 6px 15px 6px 5px;
}

.condicoes{
    margin: 6px 15px 6px 5px;
}

.footer{
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    padding: 8px 36px 9px 13px;
    background: white;
}
