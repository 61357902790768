.i-section-desc-type {
    color: rgba(0,0,0,0.6);
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    margin-bottom: 4px;
    white-space: nowrap;
    text-align: left;
}

.i-section-desc-number {
    color: rgba(0,0,0,0.82);
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.25px;
    line-height: 20px;
    white-space: nowrap;
    text-align: left;
}