@import 'assets/styles/colors.scss';

.dashboard__card-carteira {
	height: calc(100% - 32px);
	min-width: 260px;
	background: $primary_color_100_56;
	color: $secundary_color_900;
	border-radius: 4px;
	padding: 16px;
	font-size: 14px;
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: 35px 35px 80px;
	grid-template-areas:
			"header"
			"content"
			"chart";

	&__header {
			grid-area: header;
			color: $secundary_color_900;

			display: grid;
			grid-template-columns: 1fr 20px;
			grid-template-rows: auto;
			grid-template-areas: "value icon";

			&_value {
					grid-area: value;
					font-weight: bold;
					font-size: 28px;
					line-height: 35px;
			}
			&_icon {
					grid-area: icon;
					font-size: 20px;
					display: flex;
					align-items: center;
			}
	}
	&__content {
		margin-top: 8px;
			grid-area: content;
			display: flex;
			flex-direction: row;
			font-weight: 500;
			line-height: 18px;
			width: 100%;

			span {
				padding-top: 4px;
				font-size: 8px;
			}
	}
	&__chart {
		width: 100%;
		height: 100%;
		grid-area: chart;
		align-self: end;
	}
}

.dashboard__card-donut__container {
	display: grid;
	height: 100%;

	&_donut {
			display: flex;
			align-items: center;
			justify-content: center;
	}
	&_legend {
			align-self: center;
			justify-content: center;
	}
	&_button{
			position: absolute;
			top:10px;
			right: 10px;
	}
}

.dashboard-floor-plan__legenda-carteira{
	font-style: normal;
	font-weight: 450;
	font-size: 12px;
	line-height: 16px;
	color: $secundary_color_800;
	padding: 24px;
	min-width: 240px;

	&__item{
		display: grid;
    	grid-template-areas: "color label value";
		grid-template-columns: 5% 50% auto;
		align-items: center;
		align-content: space-between;
		margin: 8px 0px 8px 0px;

		&__label{
			grid-area: label;
			display: flex;
			align-items: flex-start;
			margin-left: 8px;
			text-align: start;
			color: $secundary_color_800;

			font-style: normal;
			font-weight: 450;
			font-size: 12px;
			line-height: 16px;
		}

		&__value{
			grid-area: value;
			font-weight: 450;
			font-size: 12px;
			line-height: 16px;
			color: $secundary_color_800;
			text-align: end;
		}

    .color-label{
			grid-area: color;
			border-radius: 50%;
			width: 9px;
			height: 9px;			
			margin-right: 8px;
			display: inline;
			&.color_floor_plan{
				background-color: $primary_color_600;			
			}
			&.color_fundao{
				background-color: $alert_color_200_80;
			}
		}	
	}
}
