@import 'assets/styles/colors.scss';

.radio-group-subtittle{
  font-size: 12px;
  line-height: 16px;
  color: #8F9BB3;

  margin-bottom: 6px;
  margin-left: 8px;
}

.radio-group-circular {
  color: #00afad;
  display: flex;
  justify-content: center;
  align-items: center;
}

.radio-group-error{
  display: flex;
  justify-content: center;
  align-items: center;
}

.radio-group-container{
  display: flex !important;
  flex-direction: inherit !important;
  flex-wrap:nowrap !important;
  width: calc(100% + 16px);
  min-width: 600px;
}

.radio-group-divider{
  background-color: white;
}

.radio-group-item{
  margin-right: 12px;
  height: 45px;
  width: calc(100% - 18px);

  background: #F7F9FC;
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  color: #8F9BB3;
}

.radio-group-item-checked{
  margin-right: 12px;
  height: 45px;
  width: calc(100% - 18px);

  border: 1px solid $primary_color_700;
  background: #EDF1F7;
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  color: $primary_color_700;
}

.red-asterisk {
  color: red;
}
