.acompanhamento__list-header__container {
  height: 32px;
  border-bottom: 1px solid #edf1f7;
  padding: 0 48px;

  display: grid;
  grid-template-rows: auto;
}

.acompanhamento__list-header {
  &__item {
    color: #8f9bb3;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1.5px;

    width: 100%;
    display: flex;
    align-items: center;
  }

  &__id {
    grid-area: id;
  }

  &__data {
    grid-area: data;
  }

  &__solicitante {
    grid-area: solicitante;
  }

  &__produto {
    grid-area: produto;
  }

  &__tipo {
    grid-area: tipo;
  }

  &__valor {
    grid-area: valor;
  }

  &__status {
    grid-area: status;
  }
}
