@import 'assets/styles/colors.scss';

.dashboard-floor-plan__legenda-carteiraProduto{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-right: 80px;
	
	&__item{
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;

		.nao-vencido{
			border-radius: 50%;
			width: 9px;
			height: 9px;
			background-color: $secundary_color_600;
			margin-right: 8px;
			display: inline;
		}

		.vencido{
			border-radius: 50%;
			width: 9px;
			height: 9px;
			background-color: $error_color_200;
			margin-right: 8px;
		}

		span{
			font-weight: 450;
			font-size: 12px;
			line-height: 16px;
			color: #555770;
		}
	}
}
