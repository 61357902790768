@import 'assets/styles/colors.scss';

.modal-limite-proposta{
    display: flex;
    flex-direction: column;
    background: white;
    width: 914px;
    max-height: 90%;
    border-radius: 4px;
    &__content{
        display: flex;
        flex-direction: column;
        overflow-y: auto ;
        max-height: 632px;
        padding: 24px 32px 24px 20px;

        &__header-evolucao{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
        &__evolucao{
            width: 420px;
        }
        &__sections{
            display: flex;
            flex-direction: column;
        }
        &__text{
            border-radius: 4px;
            border: 1px solid $secundary_color_100;
            background: #F7F9FC;
            color: $secundary_color_700;
            font-family: CircularStd, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 40;
            line-height: 24px;
            margin: 12px;
            padding: 16px;
            height: 150px;
            overflow-y: auto;
        }
        &__text-editor{
            .botoes-condicao{
                display: flex;
                justify-content: flex-end;
                flex-direction: row;
                align-items: center;
                gap: 16px;
                margin-top: 12px;
            }
        }
        &__text-editor-title{
            color: #2A2B38;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            margin-left: 12px;
            margin-bottom: 12px;
            margin-top: 12px;
        }
        .loading-historico{
            padding: 20px;
        }
        .option-container{
            display: flex;
            align-items: flex-start;
            flex-direction: row;
            margin-top: 24px;
            margin-bottom: 12px;
        }
    }
}

.descricao-title{
    display: flex;
    align-items: flex-start;
    flex-direction: row;
}

.radio-button-container{
    margin-top: 24px;
}