.payment-confirmation-comp {
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-rows: auto 1fr 15%;
  grid-template-areas:
    "summary-area" "list-area" "footer-area";
}

.confirmation-summary {
  grid-area: summary-area;
  justify-self: center;

  width: 90%;
  max-width: 900px;
}

.confirmation-list {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  
  grid-area: list-area;
  justify-self: center;
  vertical-align: middle;
}

.confirmation-footer {
  grid-area: footer-area;
  margin-top: 30px;
  height: 100%;
  width: 100%;

  display: grid;
  vertical-align: middle;
  grid-template-columns: 1fr auto auto;
  grid-template-areas: 
    ". btn-back btn-confirm";
}

.confirmation-btn-back {
  grid-area: btn-back;
  height: 38px;
  width: 100px;
  margin-right: 20px;
}

.confirmation-btn-confirm {
  grid-area: btn-confirm;
  height: 38px;
  width: 176px;
  margin-right: 48px;
}
