@import 'assets/styles/colors.scss';

.avista-alerta {
	&-container {
		margin-top: 16px;
	}

	&-content{
		background-color: $alert_color_200_08;
		display: flex;
		flex-direction: column;
		padding: 40px;
		border: 1px solid $alert_color_100;
		border-radius: 10px;
	}

	&-text{
		font-size: 14px;
		color: $secundary_color_800;
		letter-spacing: 0.1px;
		line-height: 24px;
		&:nth-child(1){
			padding-bottom: 24px;
		}
	}
}
