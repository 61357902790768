.modal-taxas__container{
	width: 100%;
	padding: 25px 28px 24px 28px;
	max-height: 620px;

	&__title{
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 28px;
		letter-spacing: -0.02px;
		color: #555770;
		margin-bottom: 24px;
	}

	&__tabelas{
		display: flex;
		width: 100%;
		flex-direction: column;
		gap: 16px;
		max-height: 400px;
		overflow: scroll;
		padding-right: 12px;

		&-mensagem{
			display: flex;
			width: 100%;
			justify-content: center;
			align-items: center;
		}
	}

	&__legenda{
		margin: 24px 0 20px 0;
		padding-left: 20px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 4px;

		&-titulo{
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;
			color: #7A7C9A;
		}

		&-valores{
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;
			color: #A6A8BB;
		}
	}
}
