.test-drive__modal__container__list_files {
    margin-bottom: 20px;

    display: grid;
    grid-template-columns: 1fr 40px 40px 40px;
    grid-template-rows: 24px;
    grid-template-areas: "name preview delete reload";
}

.test-drive__modal__container__list_files_name {
    grid-area: name;
    font-size: 14px;
    line-height: 24px;
color:inherit;
}

.test-drive__modal__container__list_files_preview {
    grid-area: preview;
    display:flex;
    justify-content: center;
}

.test-drive__modal__container__list_files_delete {
    grid-area: delete;
    display:flex;
    justify-content: center;
}

.test-drive__modal__container__list_files_reload {
    grid-area: reload;
    display:flex;
    justify-content: center;
}
