.common__page {
  display: grid;
  height: 100%;
  overflow-y: hidden;
  width: auto;

  grid-template-columns: 1fr;
  grid-template-rows: auto minmax(0, 1fr) 56px;

  grid-template-areas:
    "header"
    "content"
    "footer";
}

.common__page__without_footer {
  display: grid;
  height: 100%;
  overflow-y: hidden;
  width: auto;
  grid-template-columns: 1fr;
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-areas:
    "header"
    "content";
}

.common__page-header {
  grid-area: header;
  padding: 12px 48px;
}

.common__page-header__subtitle {
  font-size: 14px;
  line-height: 1.71;
  color: #8f9bb3;
}
.common__page-header__title {
  margin: 0px 0px;

  color: #555770;
  font-size: 24px;
  font-weight: 900;
}

.common__page-content {
  grid-area: content;
  height: 100%;
}

.common__page-footer {
  grid-area: footer;
  display: flex;
  border-top: solid 1px #edf1f7;
  z-index: 1;
}

