.balance-header {
    display: inline-flex;
    margin-bottom: 4px;
    span {
        height: 16px;
        min-width: 184px;
        color: rgba(0,0,0,0.82);
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 1.5px;
        line-height: 16px;
    }

    svg {
        cursor: pointer;
        width: 16px;
        height: 16px;
    }
}

.balance-last-update {
    font-size: 10px;
    font-weight: 300;
    line-height: 16px;
    color: rgba(0,0,0,0.6);
}