.modal-carteira__detalhamento-duplicata{
	margin-top: 20px;
	height: 100%;
}

.modal-carteira__detalhamento-duplicata__container{
	display: flex;
	flex-direction: column;
	padding: 0 25px 0 16px;
	height: 100%;

	&__bloco{
		border-bottom:1px solid rgba(85, 87, 112, 0.08);
		padding-top: 16px;
		padding-bottom: 16px;
		// 
		
		&__row{
			padding: 4px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			font-size: 12px;
			line-height: 16px;
	
			&__param{
				color: #555770;
			}
	
			&__value{
				color: #8F9BB3;
			}
		}
	} 

	

	


}
