@import '../../../../../../../assets/styles/colors.scss';

.modal-carteira__info{
	padding: 16px 12px 16px 24px;
	display: flex;
	flex-flow: column;
	justify-content: center;
	height: calc(100% - 32px);
	
	&__header{
		display: flex;
		padding-right: 12px;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		line-height: 24px;
		text-align: center;
		color: #555770;
		font-weight: bold;
		margin-bottom: 32px;
	}
    
	.modal-carteria__info__body{
		position: relative;
		margin-top: 16px;
		flex: 1;
		padding-right: 12px;
		&__logo{
				margin: auto auto;
				height: 48px;
				width: 48px;
				border-radius: 12px;
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				left: 0;
				right: 0;
				top: -24px;
		}

	}

	&__historico{
		display: flex;
		justify-content: center;
		font-weight: 500;
		font-size: 14px;
		color: $primary_color_600;

		&__title{
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 8px;
		}
	}
}
