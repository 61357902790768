.component-error-boundary__content {
  width: 100%;
  height: 100%;

  color: rgba(0, 0, 0, 0.52);
  line-height: 1.69;
  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
}
