@import 'assets/styles/colors';
.feedback-user__container {
  height: 100%;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 48px 1fr auto;
  grid-template-areas: 
    "header"
    "chat"
    "input";
}

.feedback-user__header {
  grid-area: header;
  display: flex;
  align-items: center;

  background-color: $primary_color_600;
  color: white;
}

.feedback-user__chat {
  grid-area: chat;
}

.feedback-user__input {
  min-height: 48px;
  grid-area: input;
}
