details[open] summary {
  background-color: #F9FAFC;
}

details[open] {
  background-color: #F9FAFC;
}

details summary:after {
  background-image: none;
}
