
.comprovante__modal__alert_pagamentos_rejeitados{
	grid-area: "alert";

	grid-template-columns: 8% 92%;
  grid-template-rows: 20px;
  display: grid;	
  grid-template-areas:
    "icon title"
		"icon message";

	background: rgba(237, 92, 111, 0.16);
	border: 1px solid rgba(237, 92, 111, 0.24);
	border-radius: 4px;
	padding: 10px 20px 10px 20px;
	row-gap: 8px;
	height: fit-content;


	&-title {
		grid-area: title;
		font-size: 14px;
		line-height: 24px;

		color: #4A0811;
  }

	&-message {
		grid-area: message;
		font-size: 12px;
		line-height: 16px;
		
		color: #ED5C6F;
  }

	&-icon {
		justify-self: center;
		grid-area: "icon";
    max-width: 70%;
    text-align: right;
		color: #555770;
  }
}
