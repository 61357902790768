@import 'assets/styles/colors.scss';

.modal-cadastro{
    display: flex;
    flex-direction: column;
    background: white;
    width: 914px;
    max-height: 90%;
    border-radius: 4px;
    &__content{
        display: flex;
        flex-direction: column;
        overflow-y: auto ;
        max-height: 632px;
        padding: 24px 32px 24px 20px;

        &__sections {
            display: flex;
            flex-direction: column;
        }

        &__text{
            border-radius: 4px;
            border: 1px solid $secundary_color_100;
            background: #F7F9FC;
            color: $secundary_color_700;
            font-family: CircularStd, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 40;
            line-height: 24px;
            margin-left: 12px;
            margin-right: 12px;
            padding: 16px;
            height: 150px;
            overflow-y: auto;
        }
        &__text-editor-title{
            margin-left: 12px;
            margin-top: 12px;
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;
            h1{
                font-family: CircularStd, sans-serif;
                color: #2A2B38;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
            }
            span{
                font-family: CircularStd, sans-serif;
                color: #505669;
                font-size: 14px;
                font-weight: 400;
                line-height: 15.18px;
            }
        }
        &__text-anexo{
            border-radius: 4px;
            border: 1px solid $secundary_color_100;
            background: #F7F9FC;
            color: $secundary_color_700;
            font-family: CircularStd, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 40;
            line-height: 24px;
            margin-left: 12px;
            margin-right: 12px;
            padding: 16px;
            height: 100px;
            overflow-y: auto;
        }

        &_header{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 12px;
            padding: 12px 0px 8px 8px;
            max-width: 600px;
        }

        &__documentos{
            display: flex;
            gap: 12px;
            margin: 8px 52px 8px 8px;
            
            &_itens{
                display: flex;
                flex-direction: column;
                width: 100%;
            }

            &__text-label{
                text-align: left;
                color: $secundary_color_700;
                font-family: CircularStd, sans-serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                margin-bottom: 6px;
                margin-left: 12px;
            }
    
            &__text-info{
                display: flex;
                padding: 8px 12px;
                border-radius: 4px;
                background: $secundary_color_100_24;
                margin-left: 6px;
                font-family: CircularStd, sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                color: $secundary_color_700;
            }
        }

        &__footer{
            padding: 0px 16px 12px 12px;
        }
    }
}
