.chat__container {
  font-size: small;
  width: 100%;
  height: 100%;
  // padding: 0 4px;
}

.chat__message-list {
  margin: 8px;
}

.chat__text-message{
  margin-top: 12px;
  margin-bottom: 4px;
}

.chat__choice-message{
  width: auto;
}

.chat__emoji-message{
  width: auto;
}
