.product-form-page {
  position: relative;

  display: grid;
  height: 100vh;
  width: auto;
  min-width: 500px;

  grid-template-columns: 48px 1fr 48px;
  grid-template-rows: auto 4fr 1fr;

  grid-template-areas:
    "  .  header   .  "
    "  .  content  .  "
    "  .  button   .  ";
}

.product-form-page-header {
  grid-area: header;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.product-form-page-content {
  grid-area: content;
  width: 100%;
  padding-top: 60px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.product-form-page-button {
  grid-area: button;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.product-form-page-title {
  margin-top: 24px;
  color:#555770;
}

.product-form-page-subtitle {
  margin-top: 12px;

  font-size: 14px;
  line-height: 1.71;
  text-align: center;
  color: rgba(143, 155, 179, 0.7);
}

.product-form-page-content-card {
  width: 235px;
  height: 268px;
  margin-right: 16px;

  &:hover {
    background: #ffffff;
    box-shadow: 
    0px 2px 4px rgba(40, 41, 61, 0.04), 
    0px 8px 16px rgba(96, 97, 112, 0.16);
  }
}

.product-cards-container {
  display: flex;
  flex-flow: wrap;
  width: 100%;
  justify-content: center;
}
