.dashboard-floor-plan__page__filter {
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  &__mensagem {
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 24px;
    color: #8F9BB3;
    margin-bottom: 16px;
  }
}
.dashboard-floor-plan_page_content {
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 16px 8px;
  grid-template-areas:
    'resumo resumo'
    'duplicataArea duplicataArea2'
    'vencidas vencidas'
    'produto produto';
    width: calc(100vw - 352px);
    margin: auto;
    padding: 12px 24px;
    overflow: hidden;
}
.resumo {
  grid-area: resumo;
}
.duplicataArea {
  grid-area: duplicataArea;
}
.duplicataArea2 {
  grid-area: duplicataArea2;
}
.vencidas {
  grid-area: vencidas;
}
.produto-brand-area {
  grid-area: produto;
  display: flex;
  .produto {
    flex: 1;
    margin-right: 4px;
  }
  .brand {
    width: 30%;
    margin-left: 4px;
    position: relative;
  }
}

.dashboard-floor-plan__alerta-mensagem{
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
