.statement-page {
    display: grid;
    height: 100%;

    grid-template-columns: min-content 1fr;
    grid-template-rows: auto auto 1fr;
    grid-template-areas: 
        "menu content";
}

.statement-page_menu{
    grid-area: menu;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.statement-page_content{
	overflow-x: auto;
    grid-area: content;
	max-height: 100%;
}

.statement-page_header {
    margin-left: 40px;
    margin-right: 40px;
}


.statement__page__root{
	display: grid;
	height: 100%;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr;
	grid-template-areas:
	"filters"
	"lista";

	&__filters{
		grid-area: filters;
	}

	&__list{
		grid-area: lista;
		height: 100%;
		max-height: 100%;

		&__alert{
			display: flex;
			height: 100%;
			width: 100%;
			align-items: center;
			justify-content: center;
		}
	}
}
