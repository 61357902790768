/* Container */
@import 'assets/styles/colors.scss';

.iof-row {
    &-section {
      display: grid;
      grid-template-areas: "checkbox icon-section description . date . value";
      grid-template-columns: 48px 48px 210px 0.8fr auto 0.2fr 8em;
      grid-template-rows: auto;
      cursor: pointer;
      padding-bottom: 16px;
      padding-top: 16px;
      margin: 0px 48px;
      border-radius: 0.5px;
      border-bottom: 1px solid $primary_color_100_24;
        &:hover {
          background-color: $primary_color_100_16;
          border-bottom: 1px solid $primary_color_100_56;
        }
    }
    
    &-activated {
        background-color: $primary_color_100_36;
    }
    
    &-checkbox {
        grid-area: checkbox;
    }
    
    &-icon {
        grid-area: icon-section;
    }
    
    &-desc {
        grid-area: description;
        margin-left: 16px;
    }
    
    &-date {
        grid-area: date;
    }
    
    &-value {
        grid-area: value;
    }
}
