@import 'assets/styles/colors.scss';

.acompanhamento__list-row__container {
    min-height: 48px;
    margin: 3px 48px;
		cursor: pointer;

    display: grid;
    grid-template-rows: auto;
}

.acompanhamento__list-row {
  &__item {
    color: #555770;
    font-size: 14px;
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__id {
    grid-area: id;
    color: $secundary_color_800;
  }
  
  &__data {
    grid-area: data;
  }

  &__solicitante {
    grid-area: solicitante;
  }

  &__produto {
    grid-area: produto;
  }

  &__tipo {
    grid-area: tipo;
  }

  &__valor {
    grid-area: valor;
  }

  &__status {
    grid-area: status;
  }
}
