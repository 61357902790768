.tabela-historico__row{
	padding: 4px 16px 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 36px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	color: #555770;

	&__processamento{
		width: 17.70%;
		align-items: flex-start;
	}

	&__pagamento{
		width: 14.70%;
		align-items: flex-start;
	}

	&__historico{
		width: 24.40%;
		align-items: flex-start;
		text-transform: uppercase;
	}

	&__documento{
		width: 13.91%;
		align-items: flex-start;
	}

	&__valor-documento{
		width: 20.37%;
		align-items: flex-start;
		font-weight: 450;
	}

	&__cheque{
		width: auto;
		align-items: flex-start;
	}
}
