.drawer-title{
    font-size: 20px;
    font-weight: 900;
    font-family: 'Circular Std', sans-serif;
    color: #555770;
}

.drawer-subtitle{
    font-family: 'Circular Std', sans-serif;
    font-weight: 450;
    font-size: 14px;
    color: #8F9BB3;
    margin-top: 5px;
}

.drawer-container{
    background-color: #F7F9FC;
    height: 100%;
}

.drawer-header {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    padding-right: 20px;
    padding-left: 20px;
}

.drawer-title-header {
    display: flex;
    flex-direction: column;
}