@import 'assets/styles/colors.scss';

.img-card-container {
  width: 235px;
  height: 268px;
  margin-right: 16px;

  border-radius: 4px;
  background-color: #fafafc;
  box-shadow:
    0 0.5px 2px 0 rgba(96, 97, 112, 0.16),
    0 0 1px 0 rgba(40, 41, 61, 0.08);
  outline: none;
  border: solid 2px transparent;

  display: grid;
  grid-template-columns: 16px 1fr 16px;
  grid-template-rows: 80px 1fr;
  grid-template-areas:
    "  .  image     .  "
    "  .  content   .  ";

  @media (max-width: 912px) {
    margin-top: 40px;
  }

  &-hover {
    &:hover {
      border-radius: 4px;
      background: #FFF;
      box-shadow: 0px 8px 16px 0px rgba(96, 97, 112, 0.16), 0px 2px 4px 0px rgba(40, 41, 61, 0.04);
    }
  }

  &-selected {
    background-color: $primary_color_100_16;
    border: solid 2px $primary_color_600;
  }
}

.img-card-content-container {
  grid-area: content;
}

.img-card-image {
  margin-top: -30px;
  max-width: 179.7px;
  max-height: 150px;

  &-container {
    grid-area: image;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
}

