.pagamento-page__list-row__container__outer{
	// margin: 0 24px;
	padding: 1px 8px 0 4px;
}

.pagamento-page__list-row__container {
  min-height: 48px;

  display: grid;
  grid-template-rows: auto;
	grid-column-gap: 8px;
	grid-template-columns: auto 8em 8em 8em 2em;
	grid-template-areas: "documento vencimento saldo pagamento icon";
  
}

.pagamento-page__list-row {
  &__item {
		color: #555770;
		font-size: 14px;
		line-height: 24px;
		width: 100%;
		display: flex;
		align-items: center;
  }

  &__identificador {
    grid-area: documento;
  }

	&__vencimento{
		grid-area: vencimento;
		color: #8F9BB3;
		justify-content: left;
	}

	&__saldo{
		grid-area: saldo;
		color: #8F9BB3;
		justify-content: center;
		font-weight: bold;
	}

	&__pagamento{
		grid-area: pagamento;
		justify-content: right;

		.common__form-input__container{
			text-align: right;
		}
	}

	&__icon{
		grid-area: icon;
		width: 24px;
	}

}
