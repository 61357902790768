.modal-taxas__filters-container{
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex: 1;
	margin-bottom: 32px;
	margin-left: -8px;

	@mixin __base{
		width: 35.05%;
		margin-right: 8px;
	}

	&__filtro{
		@include __base;
	}

	&__button{
		@include __base;
		padding-top: 20px;
	}
}