.iof-list__container {
  height: 100%;
  overflow-y: auto;
}

.iof-list__footer-text {
  height: 28px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}