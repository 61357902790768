@import 'assets/styles/colors.scss';

.currency-positive {
    color: $terciary_color_600;
}

.currency-negative {
    color: $error_color_300;
}

.currency-undefined {
    display: inline-block;
    height: 30px;
    margin: 1px auto;
    width: 10em;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
}

.currency-undefined-rect {
    display: inline-block;
    width: 95%;
    height: 40%;
    background: rgba(0,0,0,0.13);
}
