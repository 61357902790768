.td-avista-grid{
  right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    position: relative;
    height: 100%;
    cursor: pointer;
    outline: none;
} 

.td-avista-grid-content{
  top: 40%;    
}

.td-avista-grid-span{  
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 18px;
  left: 0;
  bottom: 0;
  margin-left: 8px;
}

.td-avista-row-content{
  position: absolute;
  top: 22%;
  left: 40%;
}

.td-avista-row{
  right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    position: relative;
    height: 100%;
    cursor: pointer;
    outline: none;
}

.td-avista-row-span{
  position: absolute;
  padding-right: 12px;
  padding-bottom: 4px;
  bottom: 0;
  right: 0;
}