.carteira-modal__valor{
	font-size: 14px;
	line-height: 24px;
	text-align: right;

	&-paid{
		text-decoration-line: line-through;
		color: #8F9BB3;
	}

	&-openned{
		color: #555770;
	}

	&-delayed{
		color: #ED5C6F;
	}
}
