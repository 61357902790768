.feedback-error__container {
  height: 100%;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 48px 1fr auto;
  grid-template-areas: 
    "header"
    "chat"
    "input";
}

.feedback-error__header {
  grid-area: header;
  display: flex;
  align-items: center;

  background-color: #00afad;
  color: white;
}

.feedback-error__chat {
  grid-area: chat;
}

.feedback-error__input {
  min-height: 48px;
  grid-area: input;
}
