.chat-popover__card-user-feedback__title{
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  color: rgba(0, 0, 0, 0.82);
  
  margin-top: 14px;
  margin-left: 16px;
}
.chat-popover__card-user-feedback__subtitle{
  font-size: 12px;
  line-height: 1.33;
  color: rgba(0, 0, 0, 0.6);
  margin-left: 16px;
  margin-right: 16px;
}

.chat-popover__card-user-feedback__button{
  width: 256px;
  height: 38px;

  margin-left: 16px;
  margin-top: 16px;
}