.s-page-section-value {
  margin: auto;
  margin-right: 16px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  svg {
      width: 14px;
      height: 14px;
  }
}

.statement-value__list{
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.statement-value__icon{
	margin-right: 4px;
}

.s-page-section-value-main {
  color: rgba(0,0,0,0.82);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 24px;
  text-align: right;
  margin: auto auto;
  &-processing{
    color: rgba(0,0,0,0.60);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 24px;
    text-align: right;
    margin: auto auto;
  }
}

.s-page-section-value-red {
  color: #ED5C6F;
}

.s-page-value-decoration {
  color: rgba(0,0,0,0.6);
  text-decoration: line-through;
}

.s-page-section-value-secondary {
  height: 16px;
  color: rgba(0,0,0,0.6);
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  text-align: right;
  margin: auto auto;
  svg {
      width: 10px;
      height: 10px;
  }
}

.statement-value-processing{
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #8F9BB3;
  margin: auto;
  float: right;
  svg {
    color: #8F9BB3;
    margin-right: 6px;
    vertical-align: sub;
  }
}
