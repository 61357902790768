.editor-row {
  padding-bottom: 16px;
  padding-top: 16px;
  margin: 0px 48px;
  border-bottom: 1px solid #EBEBEB;
  border-radius: 0.5px;
  transition: 0.4s;

  display: grid;
  grid-template-columns: 48px 210px 150px 146px 28px 130px 28px 130px;
  grid-template-rows: auto;
  grid-template-areas: 
    "icon description . date . total-value . paying-value";

  &:hover {
    background-color: rgba(236,236,236, 0.35);
  }
}

.editor-row-icon {
  grid-area: icon;
}

.editor-row-desc {
  margin: auto;
  grid-area: description;
  margin-left: 16px;
  margin-top: auto;
  margin-bottom: auto;
}

.editor-row-date {
  grid-area: date;
  margin: auto auto;
}

.editor-row-total-value {
  grid-area: total-value;
}

.editor-row-paying-value {
  grid-area: paying-value;

  width: 120px;
  margin: auto 18px auto;
  float: right;
}


.editor-row-paying-value-input{
  border-color: transparent;
  width: 100px;
  outline: none;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 24px;
  border-bottom: 2px solid #666666;
  &:focus{
    border-bottom: 2px solid #00afad;
  }
}