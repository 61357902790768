@import 'assets/styles/colors.scss';

.iof-sim-menu-main {
    background-color: #F9FAFC;
    min-width: 267px;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
}


.iof-sim-menu-block {
    margin: 16px;
    margin-top: 8px;
    margin-bottom: 0px;

    .iof-sim-menu-title {
        display: inline-flex;
        height: 16px;
        font-size: 10px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1.0px;
        color: $secundary_color_700;
        text-transform: uppercase;			
    }
}

.iof-sim-menu-tooltip-icon{
	width: 14px !important;
	align-self: center;
	padding: 0px 0px 3px 5px;
}

.iof-sim-menu-tooltip{
	opacity: 100%;
	max-width: 500;
}

.iof-sim-menu-block-commom {
    margin: 12px 16px 0px;
    border-bottom: 0px;
}

.iof-sim-menu-block-result {
    margin: 4px 16px 0px;
}

.iof-sim-menu-block-input {
    margin-bottom: 16px;

    .iof-sim-menu-mov-input{
        margin-top: 10px;
    }
}

.iof-sim-menu-block-divider {
    margin: 12px 16px;
    height: 0px;
    border-bottom: thin solid #e9eaf0; 
    background-color: #e9eaf0;
    &-footer{
        margin-top: 12px;
        height: 0px;
        border-bottom: thin solid #e9eaf0; 
        background-color: #e9eaf0;
    }
}

.iof-sim-menu-number {
    margin-top: 2px;
    margin-bottom: 2px;
    min-height: 24px;
}

.iof-sim-negative-value {
    display: block;
    height: 24px;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $error_color_300;
}

.iof-sim-negative-bigger {
    font-size: 24px;
}

.iof-sim-menu-default-value {
    display: block;
    height: 24px;
    font-size: 18px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $secundary_color_800;
}

.iof-sim-menu-update {
    height: 16px;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: $secundary_color_700;
}

.iof-sim-menu-legal-text {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.6);

    & > span {
        display: block;
        margin-top: 12px;
    }
}
