.carteira-page__identificador__container{
	display: flex;
	align-items: center;
	justify-content: center;

	&__dados{
		font-size: 12px;
		line-height: 16px;
		color: #8F9BB3;
		margin-left: 16px;

		&__dup{
			font-size: 14px;
			line-height: 24px;
			color: #555770;
			display: block;
		}
	}
}
