.detalhe-movimentacao__card-resumo__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  position: relative;
  margin:0 24px 0 24px;
}

.detalhe-movimentacao__card-resumo__card {
  width: 100%;
  min-height: 50px;

  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(85, 87, 112, 0.16), 0 0 2px 0 rgba(85, 87, 112, 0.08);

  color: #8f9bb3;
  font-size: 12px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &-lower {
    border-top: solid 1px rgba(85, 87, 112, 0.08);
    padding: 8px 16px;
  }
}

.detalhe-movimentacao__card-resumo{
  text-align: center;
  padding: 44px 16px 20px 16px;

  grid-template-columns: 1fr;
  grid-template-rows: 20px 20px;
  display: grid;
  grid-template-areas:
    "estorno" 
    "credito";

  &__valor-estorno {
    grid-area: "estorno";
    color: #555770;
    font-size: 24px;
    font-weight: 900;
    display: flex;

    align-items: center;
    justify-content:center;
    text-align: center;

    text-transform: capitalize;
  }

  &__credito {
    grid-area: "credito";
    line-height: 2.5;
    font-size: 12px;
		align-items: center;
    justify-content:center;
    text-align: center;
  }
}

.detalhe-movimentacao__card__data-row {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;

  &-label {
    color: #555770;
  }

  &-value {
    max-width: 70%;
    text-align: right;
  }
}


.detalhe-movimentacao__card__header {
  height: 50px;
  padding-top: 24px;
  padding-bottom: 20px;

  font-size: 14px;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &-movimentacao {
    color: #555770;
    font-weight: bold;
  }

  &-data {
    padding: 4px 0;
    color: #8f9bb3;
  }
}

.detalhe-movimentacao__card__img {
  height: 1px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	width: 100%;
}

