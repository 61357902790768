.pagamento-page__list-header__container{
  height: 32px;
  border-bottom: 1px solid #edf1f7;
	margin: 32px 0 5px;
	display: grid;
	padding: 1px 8px 0 4px;
	grid-column-gap: 8px;
	grid-template-rows: auto;
	grid-template-columns: auto 8em 8em 8em 2em;
	grid-template-areas: "documento vencimento saldo pagamento icon";

	&__item{
		font-size: 10px;
		line-height: 16px;
		letter-spacing: 1.5px;
		color: #8F9BB3;

		width: 100%;
    display: flex;
    align-items: center;
	}

	&__identificador{
		grid-area: documento;
	}

	&__vencimento{
		grid-area: vencimento;
		justify-content: left;
	}

	&__saldo{
		grid-area: saldo;
		justify-content: center;
	}

	&__pagamento{
		grid-area: pagamento;
		justify-content: flex-end;
	}

	&__icon{
		grid-area: icon;
		width: 24px
	}

}
