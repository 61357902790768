@import '../../../../../../../../../../assets/styles/colors.scss';

.modal-carteira__payment-modal__remove-button{
	width: 32px;
	height: 32px;
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.icon-white{
	background-color: #EDF1F7;
}

.icon-red{
	background-color: $error_color_100_24;
}
