.dashboard-floor-plan__area__content{
	padding: 16px;
	background: #F7F9FC;
	border-radius: 4px;
	min-height: 200px;
	height: calc(100% - 32px);
    display: flex;
    flex-flow: column;
	&-head{
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		.dashboard-floor-plan__title{
			font-size: 16px;
			line-height: 18px;
			color: #555770;
			
			span{
				display: block;
				padding-top: 4px;
				font-size: 12px;
				line-height: 16px;
				color: #A6A8BB;
			}
		}
	}
	&-children{
		flex:1;
		display: flex;
		flex-flow: column;
		height: 100%;
		align-content: center;
		justify-content: flex-end;		
		position: relative;
	}
}
