@import 'assets/styles/colors.scss';

.feedback__sending-answer {
  &__container {
    // background-color: lightgray;
    min-height: 48px;

    padding: 0 16px;

    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.25px;
    // line-height: 20px;
    color: rgba(0, 0, 0, 0.6);

    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas:
      "left right"
  }

  &__sending-text {
    grid-area: left;
    display: flex;
    align-items: center;
  }

  &__sending-spinner {
    grid-area: right;
    color:$primary_color_500;


    padding: 0 12px;
    display: flex;
    align-items: center;
  }

  &__error-text {
    grid-area: left;
    display: flex;
    align-items: center;

    color: #f84f4f;
  }

  &__error-try-again {
    grid-area: right;
    margin-left: 24px;
    border: none;
    outline: none;
    cursor: pointer;

    display: flex;
    align-items: center;

    font-weight: 500;
    color: #00afad;
  }
}