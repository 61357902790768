.las-selector {
    width: 100%;
    min-height: 60px;
    margin: auto auto;
    vertical-align: center;
  }
  
  .las-area{
    margin-left: 24px;
    margin-top: 24px;
    margin-bottom: 20px;
    min-width: 218px;
  }
  
  .las-select {
    vertical-align: inherit;
  
    &-fix{
        vertical-align: 4px;
    }    
  }
  
  .las-item  {
    border-bottom: #e9eaf0 1px solid;
    width: 100%;
    padding-bottom: 12px;
    padding-top: 12px;
    cursor: pointer;
    position: relative;
    
    &-info-container{
      top: 0;
      position: absolute;
      left: 0;
    }
  
    &-title {
      height: 16px;
      font-size: 12px;
      line-height: 1.33;
      color: rgba(0, 0, 0, 0.6);
    }
  
    &-normal {
        height: 16px;
        line-height: 1.33;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
    }
  }
  