.dealer-selector__option  {
  border-bottom: #e9eaf0 1px solid;
  width: 100%;
  padding-bottom: 12px;
  padding-top: 12px;
  cursor: pointer;

  &__title {
      height: 24px;
      font-size: 13px;
      font-weight: bold;
      line-height: 1.5;
      letter-spacing: 0.15px;
      color: rgba(0, 0, 0, 0.82);
      white-space: nowrap;

  }

  &__text {
      height: 16px;
      font-size: 11px;
      line-height: 1.33;
      color: rgba(0, 0, 0, 0.6);
  }

}