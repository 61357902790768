@import 'assets/styles/colors';

.relatorios-page__list-row__container__outer{
	margin: 0 24px;
	padding: 4px 24px;
	
}

.relatorios-page__list-row__selected {
	background-color: $primary_color_100_36;
	border: 1px solid  $primary_color_100_56;
	box-sizing: border-box;
	border-radius: 4px;

}

.relatorios-page__list-row__container {
  height: 48px;
  display: grid;
  grid-template-rows: auto;
	grid-template-areas: 'checkbox concessionaria tipoDocumento nomeDocumento data';
	grid-template-columns: 16px 260px 320px 380px 75px;;
	gap: 32px;
	@media (max-width: 1079px) {
		gap: 24px;
	}
}

.relatorios-page__list-row {
  &__item {
		color: #555770;
		font-size: 14px;
		line-height: 24px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: left;
  }

  &__checkbox {
    grid-area: checkbox;
		width: 100%;
		display: flex;
		justify-content: left;
		align-items: center;
		.relatorios-page__list-row__item__checkbox {
			border-radius: 0;
			width: 16px;
			height: 16px;
			padding: 0;
			color: $secundary_color_600;
				&:hover {
				background-color: transparent;
				}
			&.Mui-checked{
			color: $primary_color_700;
			}	
		}
  }

  
  &__concessionaria {
    grid-area: concessionaria;
		width: 100%;
  }


  &__tipo-documento {
    grid-area: tipoDocumento;
		width: 100%;
		min-width: 188px;
		max-width: 230px;
		&__text{
			white-space: nowrap;
			overflow: hidden; 
			text-overflow: ellipsis;
		}
  }

	
	&__nome-documento{
		grid-area: nomeDocumento;
		max-width: 344px;
		&__text{
			white-space: nowrap;
			overflow: hidden; 
			text-overflow: ellipsis;
		}
	}


	&__data{
		grid-area: data;
	}

}
