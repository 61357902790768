@import 'assets/styles/colors.scss';

.fidis-btn {
  box-sizing: border-box;
  min-height: 40px;
  height: 100%;
  border-radius: 4px;
  border: none;
  color: #FFFFFF;
  cursor: pointer;
  background: $primary_color_500;
      &:hover {
        background-color: $primary_color_600;
      }
        &:focus {
        background-color: $primary_color_700;
      }

  &-loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-content {
    margin: 2px 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.25px;
    line-height: 20px;
    text-align: center;
  }

  &-full-width {
    width: 100%;
  }


}

.fidis-btn-white {
  background-color: white;
  border: solid 1px #8f93a1;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
    &:hover {
      background-color: white;
    }
    &:focus {
      background-color: white;
    }
}

.fidis-btn-fill-width{
  background: $primary_color_500;
  border: none;
  font-weight: 500;
	width: 100%;
  &:hover {
	  background-color: $primary_color_600;
	}
	  &:focus {
	  background-color: $primary_color_700;
	}
}

.fidis-btn-fill-width-light{
  background-color: $primary_color_100_24;
  border: none;
  font-weight: 500;
	width: 100%;
  &:hover {
    background: $primary_color_100_56;
  }
}
