@import 'assets/styles/colors.scss';

.las__container {
  width: 94%;
  padding: 0px 0 8px 16px;
  display: grid;
  grid-template-columns: 0px 1fr;
  grid-template-rows: 24px 16px;
  
  //cursor: pointer;

  grid-template-areas:
    "icon name"
    "icon description";
}
.las-title{
  display: inline-flex;
  height: 16px;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.6;
  color: rgba(0, 0, 0, 0.6);
}

.las-name{
  grid-area: name;
	font-size: 14px;
	line-height: 24px;
	color: $secundary_color_700;
	margin-left: 16px;
	display: flex;
	flex-direction: row;
	align-self: center;
  &:hover {
    color: $secundary_color_800;
  }
  &:focus {
    color: $secundary_color_800;
  }
}

.las-description-container{
	grid-area: description;
  margin-left: 16px;

  font-size: 12px;
  line-height: 16px;

  color: #505669;
}

.las-raiz-cnpj{
	background-color: #695050;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  display: inline-flex;
  margin: 2px 4px;
}

.las-matriz{
	background-color: #8F9BB3;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  display: inline-flex;
  margin: 2px 4px;
}

.las-item-divider{
	background-color: #8F9BB3;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  display: inline-flex;
  margin: 2px 4px;
}

.las-icon{
	grid-area: icon;
  margin-top: 4px;
}