.arquivosExcecao__item__container{
  background: rgba(228, 233, 242, 0.24);
  border-radius: 4px;
  padding: 8px 12px;
  display: flex;
  flex-direction: row;
}

.arquivosExcecao__item__name{
  color: rgba(85, 87, 112, 1);
  font-size: 14px;
  line-height: 24px;
  display: block;
}

.arquivosExcecao__item__icon{
  margin-left: auto;
  margin-right: 8px;
  width: 24px;
  height: 24px;
}
