.statement_page__list-header__outer{
	border-bottom: 1px solid #edf1f7;
	padding: 3px 24px;
}

.statement_page__list-header__container{
  height: 32px;
	display: grid;
	padding: 0 31px;
	grid-template-rows: auto;
	grid-template-columns: 60% 15% 25%;
	grid-template-areas: "transacao data valor";
}

.statement_page__list-header{
	&__item{
		color: #8f9bb3;
    font-size: 10px;
		line-height: 16px;
    font-weight: normal;
    letter-spacing: 1.5px;
		text-transform: uppercase;
    width: 100%;
    display: flex;
		align-items: center;
	}

	&__transacao{
		grid-area: transacao;
		justify-content: flex-start;
		width: 100%;
	}

	&__data{
		grid-area: data;
	}

	&__saldo{
		grid-area: valor;
		justify-content: flex-end;
	}
}
