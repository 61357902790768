.acompanhamento__detalhe__card-body__content-label{
	font-size: 12px;
	line-height: 24px;
	color: #555770;
	font-weight: 700;
}

.acompanhamento__detalhe__card-body__content-row{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 4px;
}

.acompanhamento__detalhe__card-body__content-prop{
	font-size: 12px;
	line-height: 16px;
	color: #555770;
}

.acompanhamento__detalhe__card-body__content-value{
	font-size: 12px;
	line-height: 16px;
	color: #8F9BB3;
}

.solicitante{
	margin-bottom: 12px;
}
