.modal-carteira__list-row__container{
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 6px 16px;
	margin: 0 8px;
}

.modal-carteira__list-row__container:hover{
	background: #EDF1F7;
	border-radius: 4px;
}

.modal-carteira__list-row__identificador{
	display: flex;
	align-items: center;
	justify-content: center;

	&__button{
		margin-right: 16px;
	}

	&__dados{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		font-size: 12px;
		line-height: 16px;
		color: #8F9BB3;

		span{
			font-size: 14px;
			line-height: 24px;
			color: #555770;
		}
	}
}
