.i-page-section-date-desc {
    color: rgba(0,0,0,0.6);
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    margin: auto auto;
}

.i-page-section-date-value-container {
    display: grid;
    grid-template-columns: 2fr 15px auto 1fr;
    grid-template-rows: auto;
    grid-template-areas:
        "date . icon .";
}

.i-page-section-date-value {
    grid-area: date;
    color: rgba(0,0,0,0.82);
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.25px;
    line-height: 20px;
    white-space: nowrap;
}

.i-page-section-date-icon {
    grid-area: icon;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    margin: auto auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    svg {
        width: 8px;
        height: 8px;
        color: white;
        margin: auto auto;
    }
}

.i-page-section-date-icon-gray {
    background-color: rgba(127,127,127,0.5);
}

.i-page-section-date-icon-green {
    background-color: #00AFAD;
}

.i-page-section-date-icon-red {
    background-color: #ED5C6F;
}