.arquivos-execao-list__container{
  width: 100%;
  margin-top: 16px;
}

.arquivos-execao-list__subtitle{
  margin-bottom: 12px;
  color: rgba(143, 155, 179, 1);
  font-size: 12px;
  line-height: 16px;
}