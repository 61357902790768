.snackbar__itens-container{
  display: flex;           
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
 
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;

  pointer-events: none;
}