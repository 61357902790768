.carteira-page__list-header__container {
  width: auto;
  height: 32px;
  border-bottom: 1px solid #edf1f7;
  padding: 0 48px;
  margin-bottom: 5px;

  display: grid;
  grid-template-rows: auto;
  
}

.carteira-page__list-header {
  &__item {
    color: #8f9bb3;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1.5px;

    width: 100%;
    display: flex;
    align-items: center;
  }

  &__checkbox {
    grid-area: checkbox;
		width: 100%;
  }

  &__identificador {
    grid-area: documento;
		width: 100%;
  }

  &__concessionaria {
    grid-area: concessionaria;
		width: 100%;
  }

	&__vencimento{
		grid-area: vencimento;
		width: 100%;
		justify-content: right;
	}

	&__saldo{
		grid-area: saldo;
		width: 100%;

		justify-content: right;
	}

	&__status{
		grid-area: status;
		justify-content: right;
	}

}
