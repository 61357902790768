.carteira-modal__footer{
	display: flex;
	padding: 9px 24px;
	width: 100%;

	&__valor{
		display: flex;
		flex-direction: column;
		font-size: 10px;
		line-height: 16px;
		color: #8F9BB3;
		min-width: 100px;

		span{
			font-size: 14px;
			line-height: 24px;
			color: #555770;
		}
	}

	&__button{
		margin-left: 48px;
	}

}
