.test-drive-form-outlined-select {
  width: 200px;
  height: 50px;
}

.teste-drive-form-outlined-content {
  width: 90%;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 64px 64px 124px minmax(0, 1fr);
  grid-template-areas:
    "  dealer  dealer dealer   "
    " pedido chassi cor"
    "  obs obs obs   "
    "tdexcecao tdexcecao tdexcecao";
  column-gap: 26px;
  row-gap: 32px;
  
}

.test-drive-form-autocomplete-container {
  grid-area: dealer;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "  select info  ";
  margin-bottom: 12px;
}

.test-drive-form-autocomplete-container-select {
  grid-area: select;
}


.test-form-dealer-area {
  margin-top: 8px;
  grid-area:info;
  display: inline-block;
}


.test-drive-form-outline-field-container-pedido {
  grid-area: pedido;
}

.test-drive-form-outline-field-container-chassi {
  grid-area: chassi;
}
.test-drive-form-outline-field-container-cor {
  grid-area: cor;
}
.test-drive-form-outline-field-container-observacao {
  grid-area: obs;
  margin-top: 16px;
}

.test-drive-form-outline-field-container-tdexcecao {
  margin-top: 30px;
  grid-area: tdexcecao;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "  motivo anexo  ";
  column-gap: 26px;
}

.teste-drive-form-outlined-content-info-exception {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	color: #8F9BB3;
	padding-bottom: 36px;
	margin-top: -22px;
}

.test-drive-form-outline-field-container-tdexcecao-motivo {
  grid-area: motivo;
}

.test-drive-form-outline-field-container-tdexcecao-anexo {
  grid-area: anexo;
}


.test-drive-form-dealer-container {
  margin-left: 48px;
  display: inline-block;
}

.test-drive-form-dealer-tittle {
  font-size: 10px;
  line-height: 1.6;
  color: rgba(0, 0, 0, 0.6);
}

.test-drive-form-dealer-description {
  font-size: 16px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.6);
}

.dealer-selector-td-item {
  padding-bottom: 6px;
  padding-top: 6px;
  padding-left: 8px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 15px;
}
