@import '../../../../../../../assets/styles/colors.scss';

.carteira-page__filters__container{
	display: grid;
	padding: 0 48px 24px 48px;
	row-gap: 8px;
	grid-template-columns: 1fr;
	grid-template-rows: auto auto auto;
	grid-template-areas: 
	"primeira"
	"segunda"
	"terceira";

	&__primeira-camada{
		grid-area: primeira;
		display: grid;
		column-gap: 8px;
		grid-template-rows: auto;
		grid-template-columns: 1.5fr auto auto auto auto;
		grid-template-areas: "busca concessionaria showButton aplicar export";

		&__busca{
			grid-area: busca;
		}

		&__concessionaria{
			grid-area: concessionaria;
		}

		&__show-button{
			grid-area: showButton;
			width: 40px;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 4px;
			margin-top: 20px;
			cursor: pointer;
			background: $primary_color_100_24;
			&:hover {
				background: $primary_color_100_56;
			}
		}

		&__aplicar{
			padding-top: 20px;
			grid-area: aplicar;
		}

		&__export-button{
			grid-area: export;
			padding-top: 20px;
			height: 40px;
		}
	}

	&__segunda-camada{
		grid-area: segunda;
		display: grid;
		column-gap: 8px;
		grid-template-rows: auto;
		grid-template-columns: auto auto 1fr 1fr;
		grid-template-areas: "vencimento emissao status produto";

		&__vencimento{
			grid-area: vencimento;
		}

		&__emissao{
			grid-area: emissao;
		}

		&__status{
			grid-area: status;
		}

		&__produto{
			grid-area: produto;
		}
	}

	&__terceira-camada{
		grid-area: terceira;
		display: grid;
		column-gap: 8px;
		grid-template-rows: auto;
		grid-template-columns: auto auto auto auto;
		grid-template-areas: "doc modelo versao valor";

		&__doc{
			grid-area: doc;
		}

		&__modelo{
			grid-area: modelo;
		}

		&__versao{
			grid-area: versao;
		}

		&__valor{
			grid-area: valor;
		}
	}

}

.show-filters{
	background: $primary_color_100_56;
}
