.transferencia-limite__dados-solicitacao__container{
    color: #555770;
    display: grid;
    padding: 0px 40px 34px 41px;
    
    
    grid-template-columns: 1fr ;
    grid-column-gap: 10px;
    grid-template-rows: auto;
  
    grid-template-areas:
      "title    "
      "valor    ";

    &_title{
        grid-area: title;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        margin-bottom: 14px;
    }
    &_valor{
        grid-area: valor;
        margin-left: 8px;
    }
}