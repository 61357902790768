.payment-receipt-body {
  &-info-subtittle{
    font-size: 12px;
    line-height: 1.33;
    color: rgba(0, 0, 0, 0.82);
    margin-bottom: 4px;
  }

  &-info-container{
    margin-right: 32px;
    display: inline-grid;
  }
}