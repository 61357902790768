.comprovante-page {
	display: grid;
	height: 100%;

	grid-template-columns: min-content 1fr;
	grid-template-rows: auto auto 1fr;
	grid-template-areas: 
			"menu content";
}

.comprovante-page_menu{
	grid-area: menu;
	width: 100%;
	height: 100%;
	overflow-y: auto;
}

.comprovante-page_content{
	grid-area: content;
	max-height: 100%;
	// overflow-y: auto;
	.comprovante-page__header{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 25px;

		&__button{
			margin-right: -20px;
		}
	}

	.comprovante-page__body{
		display: grid;
		padding: 0 24px 0px 48px;
		column-gap: 56px;
		grid-template-rows: auto;
		grid-template-columns: auto 360px;
		grid-template-areas: " lista resumo";
		height: 100%;

		&__list{
			grid-area: lista;
			font-size: 14px;
			line-height: 24px;
			color: #8F9BB3;
			height: 100%;
		}

		&__resumo{
			grid-area: resumo;
		}

		&__resumo-buttons{
			display: grid;
			grid-template-rows: 40px;
			gap: 8px;
			width: 100%;
			grid-template-columns: 1fr 52px;
			grid-template-areas: "extrato print";
		}

		&__extrato{
			grid-area: extrato;
		}
		&__print{
			grid-area: print;
		}

	}
	.comprovante-page__body__erro{
		font-style: normal;
		font-weight: 900;
		font-size: 20px;
		line-height: 25px;
		text-align: center;
		color: #555770;
		display: inline-block;

		span{
			display: block;
			font-style: normal;
			font-weight: 450;
			font-size: 14px;
			line-height: 24px;
			text-align: center;
			color: #8F9BB3;
		}
	}
}
