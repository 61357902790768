@import 'assets/styles/colors.scss';

.test-drive-form-page {
  width: calc(85vw - 64px);
	padding-left: 12%;
	padding-right: 3.7%;

	&-header{
    margin: 56px 0px 32px;
    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
	}

	&-content {
		margin-bottom: 48px;
	}
}

.test-drive-form-page-button {
  width: 132px;
  height: 40px;
  border-radius: 10px;
  background-color: $primary_color_500;
  float: right;
  margin: 25px 0 96px;
}

.test-drive-form-page-submit {
  position: relative;
  height: 100%;
  width: 90%;
}

.test-drive-form-page-pag-index {
  vertical-align: middle;
	display:table;
	margin-top: 64px;
	margin-bottom: 40px;
  
  &-number{
    line-height: 32px;
    margin-top: 4px;
    font-size: 20px;
    font-weight: 900;
    color: #ffffff;
    display: inline-block;

    &-container {
      border-radius: 50%;
      text-align: center;
      display:table-cell;
      vertical-align:middle;
      width: 32px;
      height: 32px;
      background-color: $secundary_color_900;
    }
  }

  &-text {
    display: inline-flex;
    margin-top: 4px;
    font-size: 20px;
    margin-left: 16px;
    font-weight: 900;
    color: $secundary_color_900;
  }
}

.td-page-spinner-container {
  width: 100%;
  padding: 112px 0;

  display: flex;
  justify-content: center;
}

.td-page-no-results-container {
  padding-right: 10%;
  min-height: 60vh;
}

.td-page-empty-space-container {
  width: 100%;

  padding-top: 10%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.test-drive-simulation-input {
  margin-bottom: 32px;
}
