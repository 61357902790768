.group-selector {
  width: 100%;
  min-height: 60px;
  margin: auto auto;
  vertical-align: center;
}

.group-selector-area{
  margin-left: 24px;
  margin-top: 24px;
  margin-bottom: 20px;
  min-width: 218px;
}

.group-select {
  vertical-align: inherit;

  &-fix{
      vertical-align: 4px;
  }    
}

.group-selector-item  {
  border-bottom: #e9eaf0 1px solid;
  width: 100%;
  padding-bottom: 12px;
  padding-top: 12px;
  cursor: pointer;
  position: relative;
  
  &-info-container{
    top: 0;
    position: absolute;
    left: 0;
  }

  &-title {
    height: 16px;
    font-size: 12px;
    line-height: 1.33;
    color: rgba(0, 0, 0, 0.6);
  }

  &-normal {
      height: 16px;
      line-height: 1.33;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
  }
}

.group-selector-brand-image-fiat{
  position: absolute;
  margin-top: 4px;
  right: 0;
  top: 0;
  width: 32px;
  height: 32px;
  border-radius: 12px;
  box-shadow: 0 2px 8px 0 #e9eaf0;
  background-color: #ffffff;
  display: inline-flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  img{
    height: 13px;
    width: 17px;
  }
}

.group-selector-brand-image-jeep{
  position: absolute;
  margin-top: 4px;
  right: 0;
  top: 0;
  width: 32px;
  height: 32px;
  border-radius: 12px;
  box-shadow: 0 2px 8px 0 #e9eaf0;
  background-color: #ffffff;
  display: inline-flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  img{
    height: 8px;
    width: 22px;
  }
}

.group-selector-brand-image{
  position: absolute;
  margin-top: 4px;
  right: 0;
  top: 0;
  width: 32px;
  height: 32px;
  border-radius: 12px;
  //box-shadow: 0 2px 8px 0 #e9eaf0;
  //background-color: #ffffff;
  display: inline-flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
}
