.t-drawer-section {
    width: 420px;
}

.t-drawer-subsection {
    margin-bottom: 32px;
    margin-left: 48px;
}

.t-drawer-label {
    height: 15px;
    opacity: 0.7;
    color: #3A3D42;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.21px;
    line-height: 15px;
    margin-bottom: 4px;
}

.t-drawer-data {
    height: 23px;
    color: #3A3D42;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.32px;
    line-height: 23px;
}

.t-drawer-title-label {
    height: 18px;
    opacity: 0.7;
    color: #3A3D42;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.25px;
    line-height: 18px;
    margin-bottom: 8px;
}

/* Header area */
#i-drawer-close {
    grid-area: close-btn;
}

#t-close-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

#t-drawer-logo{
    grid-area: logo;
}

#t-drawer-logo > img {
    float: right;
}


.t-drawer-header {
    margin: 32px 48px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: 
      "close-btn logo"
}

/* Title area */
#t-drawer-transfer {
    height: 23px;
    color: #3A3D42;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0.32px;
    line-height: 23px;
}

/* Value area */
#t-drawer-value-negative {
    width: 324px;
    margin-top: 8px;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0.57px;
    line-height: 41px;
}


/* Bank info */
.t-drawer-bank-line {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: 
      "leftEl rigthEl"
}

.t-drawer-bank-left {
    grid-area: leftEl;
    margin-bottom: 12px;
}


.t-drawer-bank-rigth {
    grid-area: rigthEl;
    margin-bottom: 12px;
}

