.comprovante-page__list-header__container{
  height: 32px;
  border-bottom: 1px solid #edf1f7;
	margin: 32px 0 5px;
	display: grid;
	padding: 1px 4px 0 4px;
	grid-template-rows: auto;
	grid-template-columns: 18em 8em auto 7em 3em;
	grid-template-areas: "identificador origem destino pagamento icone";

	&__item{
		font-size: 10px;
		line-height: 16px;
		letter-spacing: 1.5px;
		color: #8F9BB3;

		width: 100%;
    display: flex;
    align-items: center;
	}

	&__identificador{
		grid-area: identificador;
	}

	&__origem{
		grid-area: origem;
		justify-content: left;
	}

	&__destino{
		grid-area: destino;
		justify-content: left;
	}

	&__pagamento{
		grid-area: pagamento;
		justify-content: right;
	}

	&__icon{
		grid-area: icone;
		width: 24px;
	}

}
