.carteira-page {
	display: grid;
	height: 100%;

	grid-template-columns: min-content 1fr;
	grid-template-rows: auto auto 1fr;
	grid-template-areas: 
			"menu content";

	.common__page-header__title{
		display: flex;
		justify-content: space-between;
	}
}

.carteira-page__button-modal__title{
	font-style: normal;
	font-weight: 450;
	font-size: 14px;
	line-height: 16px;
	text-decoration-line: underline;
	color: #8F9BB3;
	display: flex;
	flex-direction: row;
	align-items: unset;

	span{
		margin-right: 8px;
	}
}

.carteira-page_menu{
	grid-area: menu;
	width: 100%;
	height: 100%;
	overflow-y: auto;
}

.carteira-page_content{
	overflow-x: auto;
	grid-area: content;
	max-height: 100%;
}

.carteira-page__conteudo{
	height: 100%;	
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: auto auto 1fr;
	grid-template-areas: 
	"filters"
	"warning"
	"lista";

	&__filters{
		grid-area: filters;
	}

	&__warning{
		grid-area: warning;
	}

	&__list{
		grid-area: lista;
		height: 100%;

		&-alerta{
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
