.payment-receipt-body {
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 50% 50%;
  grid-template-areas: 
    "dealer user"
    "request .  "
    "list  list"
  ;

  &-user-info{
    grid-area: user;
    display: inline-block;
  }

  &-dealer-info{
    grid-area: dealer;
    display: inline-block;
  }

  &-pay-info{
    grid-area: request;
    margin-top: 32px;
  }

  &-listing{
    grid-area: list;
  }


  &-listing-tittle{
    font-size: 12px;
    line-height: 1.33;
    color: rgba(0, 0, 0, 0.82);
    margin-bottom: 16px;
    margin-top: 48px;
  }

  &-loading{
    margin-left: 45%;
    margin-top: 10%;
  }
}