.pagamento-page__alerta-error{
	padding: 16px 16px 16px 18px;
	background: rgba(255, 182, 0, 0.08);
	border: 1px solid rgba(255, 219, 128, 1);
	border-radius: 4px;
	margin-top: 16px;
	margin-bottom: 26px;

	&__tittle{
		font-size: 14px;
		font-weight: 700;
		line-height: 24px;
		color: #555770;
		background-color: transparent;
		display: flex;
		align-items: center;
		margin-bottom: 8px;
	}

	&__body{
		margin-left: 32px;
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
		color: #555770;
		background-color: transparent;
	}
}
