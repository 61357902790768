.saldo__remanescente {
  height: 30px;
  color: rgba(0,0,0,0.82);
  font-size: 24px;
  font-weight: 900;
  line-height: 30px;
  margin-bottom: 4px;
  display: block;
}

.saldo__bloqueado {
  height: 18px;
  color: rgba(0,0,0,0.6);
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 4px;

  span{
      vertical-align: 0.5px;
  }

  svg {
      margin-left: 4px;
      width: 12px;
      height: 12px;
  }
}
