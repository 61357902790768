.statement-page__filters{
	display: grid;
	// grid-template-rows: auto;
	grid-gap: 8px;
	grid-template-columns: 1fr auto auto auto;
	grid-template-areas: "texto tipo data aplicar";
	padding: 0 40px 24px;

	&__text{
		grid-area: texto;
		width: 100%;
	}

	&__tipo{
		grid-area: tipo;
		// width: fit-content;
		min-width: 172px;
		padding-top: 26px;
		margin-left: -6px;
	}

	&__data{
		grid-area: data;
		width: fit-content;
		padding-top: 18px;
	}

	&__aplicar{
		grid-area: aplicar;
		padding-top: 20px;
	}
}
