.date-picker-container {
    position: absolute;
}

.button-clear {
    display: inline-block;
    position: absolute;
    top: 50%;
    height: 30px;
    margin-top: -7px;
    margin-left: 7px;
    padding: 2px;

    background: none;
    border: none;

    font-size: 22px;
    color: rgba(0, 0, 0, 0.27);
}

.button-clear:focus {
    outline: none;
}

.button-clear:hover {
    color: #00AFAD;
}

.border {
    height: 40px;
    min-width: 230px;

    border: 1px solid rgba(0, 0, 0, 0.13);
    border-radius: 8px;
    padding-left: 10px;

    position: relative;
    display: inline-block;
}

.border_active {
    border-color: #00AFAD;
}

.header {
    display: block;
    height: 18px;
    width: 120px;

    margin-top: -10px;
    margin-left: 5px;
    padding-left: 5px;
    background:white;

    font-family: "Circular Std";
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.13);
}

.header_active {
    color: #00AFAD;
}

.DateRangePicker *, .SingleDatePicker *, .DayPickerRangeController * {
    box-sizing: border-box;
}

.date-picker-input-container {
    position: absolute;
    top: 50%;
    height: 28px;
    margin-top: -14px;
}

.DateRangePickerInput {
    background: transparent;

    font-family: "Circular Std";
    font-size: 14px;
    font-weight: 300;
}

.DateInput__small {
    width: 95px;
}

.DateInput_input__small {
    padding: 3px 3px 1px;
    text-align: center;
}

.DateInput_input__small::placeholder {
    color: rgba(0, 0, 0, 0.13)
}

.DateInput_input__focused {
    border-bottom: 2px solid rgba(0, 0, 0, 0.13);
}

.DateRangePickerInput_arrow {
    color: rgba(0, 0, 0, 0.13);
}

.arrow-item_active {
    color: #484848;
}

.DateRangePickerInput_calendarIcon {
    padding: 2px;
    padding-top: 4px;
    margin-left: 4px;
    color: rgba(0, 0, 0, 0.27);
    font-size: 20px;
}

.calendar-icon_active {
    color: #00AFAD;
}

.DateRangePicker_picker__directionLeft{
    left: -52px !important;
}
