.iof-error-box__button {
  width: 150px;
  height: 38px;
  border-radius: 10px;
  cursor: pointer;
  color: #00afad;
  
  span{
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.25px;
    text-align: center;
    color: #ffffff;
  }
}
