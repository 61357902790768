.menu-dashboard__container {
	height: 100%;
	width: 100%;
	background-color: #f7f9fc;
}

.menu-dashboard__item {
	padding: 4px 8px;
}

.menu-dashboard__container__title {
	padding: 17px 0px 14px 0px;
	margin: 0px 24px 0px 24px;
	border-bottom: solid 1px #e4e9f2;
	display: flex;
	color: #555770;
	align-items: center;
	&_text {
			font-size: 14px;
			line-height: 24px;
			color: #555770;
	}

	&_icon {
			display: flex;
			margin-right: 18px;
			align-items: center;
			color: #555770;
	}
}

.menu-dashboard__container__content {
	padding-top: 14px;

}
