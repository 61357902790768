@import 'assets/styles/colors.scss';

.card__container__error{
	display: flex;
	background-color: $primary_color_100_56;
	flex-direction: column;
	align-items: center;
	text-align: center;
	justify-content: center;
	color: $secundary_color_900;
	border-radius: 4px;

	&_icon {
			margin-bottom: 4px;
			color: $error_color_300;
	}
	&_message {
			margin-top: 10px;
			max-width: 80px;
	}
}

.card__loading{
	background-color: $primary_color_100_56;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: $primary_color_800;
	border-radius: 4px;
}
