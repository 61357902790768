.acompanhamento__detalhe{
	height: 100%;
	background: linear-gradient(180deg, #F6F8FA 0%, rgba(246, 248, 250, 0.0001) 100%);
  padding: 12px 0 20px;
	display: grid;
  grid-template-columns: auto;
	grid-template-rows: auto auto 1fr;
	grid-template-areas: 
	"head"
	"cards"
	"timeline";
}


.acompanhamento__detalhe__header{
	grid-area: head;
	padding: 0 24px;
}

.acompanhamento__detalhe__cards{
	grid-area: cards;
	padding: 0 24px;
}

.acompanhamento__detalhe__timeline{
	height: 100%;
	margin-top: 12px;
	border-top: 1px solid rgba(85, 87, 112, 0.08);
	grid-area: timeline;	
	&-content{
		padding: 0 24px;
		height: 100%;
	}
}

.acompanhamento__detalhe__error{
	padding: 0 24px;	
}
