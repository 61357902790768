.comprovante-page__resumo-card{
	background: rgba(228, 233, 242, 0.32);
	border-radius: 8px;
	padding: 16px 0 20px;
	margin-bottom: 16px;

	&__header{
		font-size: 14px;
		line-height: 24px;
		color: #555770;
		padding: 0 16px 16px;
		border-bottom: 1px solid rgba(85, 87, 112, 0.08);

		&__info{
			padding-top: 12px;
		}
	}

		&__row{
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-top: 8px;
		}

		&__prop{
			font-size: 12px;
			line-height: 16px;
			color: #555770;
		}

		&__value{
			font-size: 12px;
			line-height: 16px;
			color: #8F9BB3;
			display: flex;
			align-items: center;
		}

		&__user{
			padding: 16px;
			display: flex;
			align-items: center;
			border-bottom: 1px solid rgba(85, 87, 112, 0.08);
		}

		&__user-name{
			font-size: 14px;
			line-height: 24px;
			color: #555770;
			display: block;
			margin-left: 12px;

			span{
				display: block;
				font-size: 12px;
				line-height: 16px;
				color: #8F9BB3;
			}
		}

		&__info-dealers{
			padding: 8px 16px 16px;
			border-bottom: 1px solid rgba(85, 87, 112, 0.08);
		}

		&__footer{
			padding: 8px 16px 0;
			
			.error{
				color:#ED5C6F;
			}

			.total{
				font-size: 16px;
				line-height: 24px;
				letter-spacing: 0.15px;
			}

		  .positivo{
				color: #00AFAD;
			}

			.negativo{
				color: #ED5C6F;
			}
		}


}
