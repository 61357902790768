.login-box {
	display: flex;
    flex-direction: column;
    border: 0 solid #0C1C2D;
    border-radius: 11px;
    margin: 15vh auto;
    position: relative;
    height: auto;
		align-items: center;
		width: 38.5%;
		
		&__alerta{

			align-items: center;
			justify-content: center;
			width: 330px;
		}
}

.login-entry{
    margin-left: 0px;
}
.login-label {
	display: flex;
	align-items: center;
  margin-bottom: 4px;
	margin-left: 10px;

	font-family: CircularStd;
	font-size: 12px;
	line-height: 16px;
	/* identical to box height, or 133% */	
	color: #8F9BB3;
}

.user-label{
	margin-bottom: 0px;
}

.user-type-form-div {
    margin-bottom: 16px;
}

.login-titulo {
    width: 100%;
    color: #0C1C2D;
    font-weight: bold;
    font-size: 27px;
    line-height: 32px;
    margin-bottom: 30px;
		text-align: center;

		font-family: CircularStd;
		font-size: 24px;
		line-height: 28px;

/* Color / Fill / 6. Dark / (3) */

color: #555770;
}

.loginButtonDiv {
    width: fit-content;
    margin-top: 10px;
}

.login {
    height: 12px;
    width: 38px;
    color: #0C1C2D;
    font-size: 12px;
    line-height: 14px;
    margin-top: 30px;
    margin-bottom: 10px;
}

.feather-user {
    height: 20px;
    width: 20px;
    margin-bottom: auto;
}

.input-text {
    width: fit-content;
		margin-bottom: 16px;
}

.button {
    text-align: center;
    height: 40px;
    width: 400px;
    border-radius: 2px;
}

.buttonProgress {
    margin-top: -12;
    margin-left: -12;
		color: #FFFFFF;
}
  
.text-button {
		font-family: CircularStd;
		display: contents;
    height: 14px;
    width: 66px;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 17px;
}
  
.search-component {
    display: inline-flex;
}

.icon-wrapper{
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 10px;
}

.loginErrorDiv {
	margin-top: 4px;
  margin-bottom: 0px;
	width: 330px;
}

#loginErrorMessage {
		font-family: 'Helvetica Neue LT Std', sans-serif;
    color: #ED5C6F;
    font-size: 10px;
    font-weight: 500;
		margin-top: 4px;
}

.labelRadioLogin{
    font-family: 'Helvetica Neue LT Std', sans-serif;
    font-size: 14px;
}

.password-label{
    display: inline-flex;
		margin-bottom: 0px;
		margin-top: 0px
		
}

.forget-pass-container{
		display: inline-flex;
		align-self: center;
		margin-top: 11px;
		padding-bottom: 38px;

    :hover {        
				color: #8F9BB3;
    }

    a {
			color: #555770;
      text-decoration: underline;
    }
}

.forget-your-pass-label{
    color: 555770;
    font-size: 12px;
}


.login-divider {
	margin-top: 38px;
	margin-bottom: 38px;
}


.first-access-container{
	display: grid;
	text-align: center;
	padding-top: 38px;
	border-top: 1px solid rgba(85, 87, 112, 0.08);

}

.first-access-label{
	margin-right: 16px;
	width: 100%;
	height: 14px;
	font-size: 14px;
	line-height: 1;
	color: rgba(0, 0, 0, 0.6);
	text-align: center;
}

.first-access-button{
	outline: none;
	width: 330px;
	height: 38px;
	border-radius: 3px;
	border: solid 1px #555770;
	background-color: white;
	font-size: 14px;
	line-height: 1.43;
	letter-spacing: 0.25px;
	text-align: center;
	margin-top: 11px;

	&:active {
			outline: none;
	}

	&:hover {
			cursor: pointer;
			span {
					color: #555770;;
			}
	}
}

.first-access-button-label{
	color: rgba(0, 0, 0, 0.6);
}

.first-access-link-button{
	display: inline-flex;
}
