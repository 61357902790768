.common__close-modal__button {
    background-color: inherit;
    height: 100%;
    width: 100%;
    color: inherit;
    font-size: inherit;
    outline: none;
    white-space: nowrap;
    border: none;
    border-radius: 4px;
}

.common__close-modal__close-icon {
    width: 24px;
    height: 24px;
    margin-left: 4px;
}

.common__close-modal__close-text {
    margin-top: -2px;
    font-size: 10px;
}

.common__close-modal__close-text-color{
	margin-top: -2px;
  font-size: 10px;
	color: white;
}
