.feedback-dialog-line {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.82);
    p {
        margin: 0;
    }
    img {
        padding-top: 32px;
    }
    &:last-child {
        padding: 16px 71px 40px 71px;
    }
    
}

.feedback-dialog {
    padding: 48px 48px;
}

#feedback-dialog-close {
    svg {
        position: absolute;
        right: 0;
        margin: 21px;
        width: 24px;
        height: 24px;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.5);
    }
}