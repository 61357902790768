.transferencia-limite__modal__container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 90px minmax(0, 1fr) 56px;
  grid-template-areas:
    "title"
    "content"
    "footer";

    &_title {
      grid-area: title;
      color: #555770;
      font-size: 24px;
      font-weight: 900;
      padding: 37px 40px 18px 40px;
    }
    &_content{
      grid-area: content;
      display: flex;
      flex-direction: column;
    }
    &_footer{
      font-size: 14px;
      font-weight: 500;
      border-top: 1px solid rgba(85, 87, 112, 0.1);
      padding: 8px 40px 0px 40px;
      grid-area: footer;
      display: flex;
      justify-content: flex-end;
    }
}