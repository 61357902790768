@import 'assets/styles/colors.scss';

.placeholder-select{
  font-family: CircularStd, sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: $secundary_color_700;
}

.label-select{
  font-family: CircularStd, sans-serif;
  color: $secundary_color_700;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 12px;
}

.label-select-no-margin{
  font-family: CircularStd, sans-serif;
  color: $secundary_color_700;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 0px; 
}

.all-item{
  font-family: CircularStd, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #555770;
}

.placeholder{
  font-family: CircularStd, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #555770;
}