@import '../../../../assets/styles/colors.scss';

.login-component{
    width: 100%;
    display: flex;
    background: $gradientLogin;
    background-size: cover;
}


.loading-logo{
    background-color: rgba(12,28,45,0.8);
    margin: auto;
    height: 41px;
}

.entry-background{
    height: 100vh;
    width: 70%;
    background-color:white;
		display: flex;
		align-items: center;
		justify-content: center;
}

.logo-stellantis {
    height: 99px;
    margin: auto 0 auto 0;
}

.title-box{
    margin: 15vh auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    #stellantis-logo {
        display: flex;
        flex-direction: row;
    }
}

.divisao {
	margin-top:20px;
    background-color: #B4FDDD;   
}

.easy-tracking {
    height: 26px;
    width: 214px;
    color: #FFFFFF;
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 31px;
    margin: auto auto auto 0;
}

.login-subtitle {
    height: 18px;
    width: 200px;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: bold;
    line-height: 18px;
    margin: 0px 420px 26px 0;
    margin-left: 90px;
}

.card-login-subtitle{
    position: relative;
    text-align: center;
}

.card-login {
    display: flex;
    width: 50%;
    grid-template-rows: max-content;
    height: 100%;
    position: relative;
    &::before{
        background: url("../../../../assets//icons/iconsRebranding/login-pattern.svg");
        content: '';
        background-size: cover;
        width: 100%;
        height: 70vh;
        justify-self: end;
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

.info-box {
    margin: 26px 144px 0 72px;
}


.user-entry{
    float: right;
    margin: auto;
    margin-top: 200px;		
}


.faca-seu-login {
    height: 27px;
    width: 189px;
    color: #0C1C2D;
    font-size: 27px;
    line-height: 32px;
}
