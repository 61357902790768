.pedidos__modal__comentarios-list__container {
    height: 100%;
    width: 100%;
}

.pedidos__modal__comentarios-list__message-container {
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
}

.pedidos__modal__comentarios-list__container_content {
    margin-bottom: 20px;
}