.payment-page {
    height: 100vh;
    overflow: hidden;

}

.payment-content {
    height: 100vh;
    grid-area:content
}

.payment-content-no-header{
    height: 80vh;
}

.payment-page-content{
    grid-area: content;
    overflow-x: auto;
    overflow-y: auto;
}


.internet-banking-page{
    display:grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: auto auto 1fr;
    grid-template-areas: "menu content";
}

.internet-banking-menu-container{
    grid-area:menu;
}
