.acompanhamento__detalhe__card-aumento{
	background-color: #FFFFFF;
	margin-top: 8px;
	box-shadow: 0px 0px 2px rgba(85, 87, 112, 0.08), 0px 4px 8px rgba(85, 87, 112, 0.16);
	-webkit-box-shadow: 0px 0px 2px rgba(85, 87, 112, 0.08), 0px 4px 8px rgba(85, 87, 112, 0.16);
 	-moz-box-shadow: 0px 0px 2px rgba(85, 87, 112, 0.08), 0px 4px 8px rgba(85, 87, 112, 0.16);
	border-radius: 8px;
}

.acompanhamento__detalhe__card-aumento__header{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 12px 0 16px;
	border-bottom: 1px solid rgba(85, 87, 112, 0.08);

	&-title{
		font-size: 12px;
		line-height: 16px;
		color: #8F9BB3;
	}

	&-valor{
		font-size: 24px;
		line-height: 28px;
		color: #555770;
		font-weight: bold;
	}
}

.acompanhamento__detalhe__card-aumento__content{
	padding: 12px 16px 16px;
}
