.solicitacao-limite__autocomplete-container{
  width: calc(100% - 8px);
  background-color: rgba(228, 233, 242, 0.24);
  outline: none;
}

.solicitacao-page__autocomplete-subtittle{
  font-size: 12px;
  line-height: 16px;
  color: #8F9BB3;

  margin-bottom: 6px;
  margin-left: 8px;
}

.solicitacao-limite__autocomplete-item-container{
  background-color: #F7F9FC;
  width: 100%;
  padding: 8px 0 8px 8px;

  display: grid;
  grid-template-columns: 32px 1fr;
  grid-template-rows: 24px 16px;
  
  cursor: pointer;

  grid-template-areas:
    " icon   name          "
    " icon   description   ";
}

.solicitacao-limite__autocomplete-item-icon {
  grid-area: icon;
  margin-top: 4px;
}

.solicitacao-limite__autocomplete-item-name {
  grid-area: name;
  font-size: 14px;
  line-height: 24px;

  color: #555770;

  margin-left: 16px;

  display: flex;
  flex-direction: row;
}

.solicitacao-limite__autocomplete-item-description {
  grid-area: description;
  margin-left: 16px;

  font-size: 12px;
  line-height: 16px;

  color: #8F9BB3;
}

.solicitacao-limite__autocomplete-item-description-container {
  display: inline-flex;
}

.solicitacao-limite__autocomplete-item-divider{
  background-color: #8F9BB3;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  display: inline-flex;
  margin: 2px 4px;
}

.solicitacao-limite__autocomplete-item-icon-navigate{
  color: #555770;
  height: 12px;
  transform: rotate(270deg);

  margin-right: 16px;
  margin-left: auto;
  margin-top: 8px;
  margin-bottom: 0px;
}