.transferencia-limite__dados__container {
    font-size: 11px;
    color: #8f9bb3;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 64px 64px 64px;
    padding: 24px 40px 34px 48px;
    border-top: 1px solid rgba(85, 87, 112, 0.1);

    grid-template-areas:
        "concessionaria   concessionaria   concessionaria   concessionaria"
        "codanagrafico        cnpj          codregional       regional"
        "sap                produto           produto            produto";


    &__concessionaria {
        grid-area: concessionaria;
    }
    &_codanagrafico {
        grid-area: codanagrafico;
    }
    &_cnpj {
        grid-area: cnpj;
    }
    &_codregional {
        grid-area: codregional;
    }
    &_regional {
        grid-area: regional;
    }
    &_sap {
        grid-area: sap;
    }
    &_produto {
        grid-area: produto;
    }
}
