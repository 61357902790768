.detalheMovimentacao__title{
	margin-top: 16px;
	font-size: 14px;
	line-height: 24px;

	text-align: center;
	color: #555770;
}

.detalheMovimentacao__date_time{
	margin-bottom: 16px;
	font-size: 14px;
	line-height: 24px;

	text-align: center;
	color: #8F9BB3;
}

.detalheMovimentacao__divider{
	margin-top: 12px;
	margin-bottom: 12px;
}

.detalheMovimentacao__pendency {
	padding-top: 40px;
}

.detalheMovimentacao__pendency_code {
	font-size: 12px;
	line-height: 16px;
	/* identical to box height, or 133% */
	text-align: center;

	color: #8F9BB3;
}

.detalheMovimentacao__pendency_value {
font-size: 24px;
line-height: 28px;
text-align: center;

color: #555770;
}

.detalheMovimentacao__pendency_credit {
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	color: #8F9BB3;
}

.detalheMovimentacao__payment_date {
	display: inline-flex;
	width: -webkit-fill-available;
	margin-left: 16px;
	font-size: 12px;
	line-height: 16px;
	color: #555770;
}

.detalheMovimentacao__duplicata {
	display: inline-flex;

	width: -webkit-fill-available;
	margin-left: 16px;
	font-size: 12px;
	line-height: 16px;

	color: #555770;
	}

.detalheMovimentacao__container {
	background-color: rgba(246, 248, 250, 0.5);
	width: auto;
  	padding: 0px 24px 16px 24px;
    height: calc(100% - 16px);
}

.detalheMovimentacao__modal__detalhes {
	grid-area: "card";
	position: relative;
}

.detalheMovimentacao__modal__mensagem {
	grid-area: "messages";
	margin-top: 14px;
	border-top: 1px solid rgba(85, 87, 112, 0.1);
	height: 100%;
	&_timeline {
		height: 100%;
		padding-left: 24px;
	}
}
