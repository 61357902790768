.receipt-list-container {
  height: 100%;
  
  font-size: 14px;
  color: rgba(0,0,0,0.82);	
  letter-spacing: 0.25px;
  line-height: 1.43;
  text-align: right;
  margin: auto 16px auto;

  display: grid;
  grid-template-rows: 48px 1fr;
  grid-template-columns: 100px 2fr 180px 2fr 180px 6fr 180px;

  grid-template-areas:
    "hd-inv . hd-due . hd-balance . hd-pay sidebar"
    "list list list list list list list .";
}

.receipt-list-header {
  justify-self: left;
  vertical-align: middle;
  margin: auto 0;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.6);


  &-inv {
    grid-area: hd-inv;
    margin-left: 16px;
  }

  &-due {
    grid-area: hd-due;
    margin-left: -8px;
  }

  &-balance {
    grid-area: hd-balance;
    margin-left: -8px;
  }

  &-pay {
    grid-area: hd-pay;
    justify-self: right;
    margin-right: 28px;
  }
}

.receipt-rows-container {
  grid-area: list;
  width: 100%;
  height: 100%;
  // overflow-y: scroll;
}

.receipt-row {
  margin-right: 16px;
  padding-bottom: 16px;
  padding-top: 16px;
  border-bottom: 1px solid #EBEBEB;
  border-radius: 0.5px;
  transition: 0.4s;

  display: grid;
  grid-template-columns: 100px 2fr 180px 2fr 180px 6fr 180px;
  grid-template-areas: 
    "id . invoiceOrigem . invoiceDestino . paying-value";

  &:hover {
    background-color: rgba(236,236,236, 0.35);
  }
}

.receipt-row-number {
  grid-area: id;
  margin-left: 16px;
  justify-self: left;

  white-space: nowrap;
  color: rgba(0, 0, 0, 0.82);
}

.receipt-row-value-origem {
  grid-area: invoiceOrigem;
  justify-self: left;
}

.receipt-row-value-destino {
  grid-area: invoiceDestino;
  justify-self: left;
  margin: auto 0;
}

.receipt-row-paying-value {
  grid-area: paying-value;
  color: rgba(0,0,0,0.82);
	font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 1.43;
  text-align: right;
  margin: auto 10px auto;
}
