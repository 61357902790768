.relatorios-page__conteudo {
	height: 100%;
	display: flex;
	flex-direction: column;
	flex: 1;

	&__list{
		height: 100%;
	}

	&__list__mensagem { 
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		position: relative;
	}

}

.relatorios-page__conteudo__alerta{
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
