.payment-confirmation-container{
  width: 451px;
  height: 239px;
  border-radius: 11px;
  text-align: center;
  margin: auto auto;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}

.payment-confirmation-image-container{
  display: flex;
  padding-top: 32px;
  margin-left: 45%;
  object-fit: contain;
}

.payment-confirmation-image{
  width: 46.7px;
  height: 46.7px;
  color: #00afad;
}

.payment-confirmation-span{
  font-size: 20px;
  font-weight: 900;
  text-align: center;
  margin-bottom: 32px;
  margin-top: 24px;
  padding-left: 48px;
  padding-right: 48px;
  color: rgba(0, 0, 0, 0.82);
}
.payment-confirmation-button-container{
  padding-bottom: 32px;
}

.payment-confirmation-button-container-error{
  padding-bottom: 32px;
  width: 100%;
  position: relative;
  height: 38px;
}

.payment-confirmation-button-error{
  height: 38px;
  width: 110px;
  border-radius: 10px;
  display: flex;
  position: absolute;
  right: 0;
  margin-right: 214px;
  border: rgba(0, 0, 0, 0.02);
}

.payment-confirmation-button-refresh{
  height: 38px;
  width: 150px;
  border-radius: 10px;
  position: absolute;
  right: 0;
  margin-right: 48px;
  background-color: #00afad;
}

.payment-confirmation-button{
  width: 52px;
  height: 38px;
  border-radius: 10px;
  display: flex;

  background-color: #00afad;
}

.payment-modal-line-bigger{
  height: 25px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: rgba(0, 0, 0, 0.82);
}