@import '../../../../../../../../assets/styles/colors.scss';

.modal-carteira__card-info__container{
	display: flex;
	flex-direction: column;
	padding-bottom:  -4px;
	background: #FFFFFF;
	box-shadow: 0px 0px 2px rgba(85, 87, 112, 0.08), 0px 4px 8px rgba(85, 87, 112, 0.16);
	border-radius: 8px;

	&__header{
		padding: 40px 16px 16px 16px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		font-size: 12px;
		line-height: 16px;
		color: #8F9BB3;

		&__valor{
			font-size: 24px;
			line-height: 28px;
			text-align: center;
			font-weight: bold;
			color: #555770;
		}

		&__subtitle{
			padding-top: 16px;
		}

		&__tag{
			font-size: 10px;
			line-height: 16px;
			padding: 4px;
			border-radius: 4px;
		}

		&__message{
			font-size: 12px;
			line-height: 16px;
			text-align: center;
			color: $error_color_300;
		}
	}

	&__veiculo{
		margin-top: 12px;
		border-top: 1px solid rgba(85, 87, 112, 0.08);
		padding: 16px 0 8px;
	}

	&__row{
		padding: 0 16px 8px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		font-size: 12px;
		line-height: 16px;

		&__param{
			color: #555770;
		}

		&__value{
			color: #8F9BB3;
		}
	}

	&__duplicata{
		border-top: 1px solid rgba(85, 87, 112, 0.08);
		border-bottom: 1px solid rgba(85, 87, 112, 0.08);
		padding: 16px 0 8px;
	}

	&__footer{
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 16px;
	}
}
