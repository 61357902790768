.relatorios-page__list-header__container {
  height: 32px;
  border-bottom: 1px solid #edf1f7;
  padding: 0 48px;
  margin-bottom: 5px;

  display: grid;
  grid-template-rows: auto;
	grid-template-areas: 'checkbox concessionaria tipoDocumento nomeDocumento data';
	grid-template-columns: 16px 260px 320px 380px 75px;;
	gap: 32px;
	@media (max-width: 1079px) {
		gap: 18px;
	}
}

.relatorios-page__list-header {
  &__item {
    color: #8f9bb3;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1.5px;
		line-height: 16px;
    width: 100%;
    display: flex;
    align-items: left;
  }

  &__checkbox {
    grid-area: checkbox;
		width: 100%;
		display: flex;
		align-items: left;
		.relatorios-page__list-header__item__checkbox {
			border-radius: 0;
			width: 16px;
			height: 16px;
			padding: 0;
			color: rgba(85, 87, 112, 1);
				&:hover {
				background-color: transparent;
				}
			&.Mui-checked{
			color: #555770;
			}	
		}
  }
	



	&__concessionaria {
    grid-area: concessionaria;
		width: 100%;
  }
	

  &__tipo-documento {
    grid-area: tipoDocumento;
		width: 100%;
		min-width: 188px;
  }

  

	&__nome-documento{
		grid-area: nomeDocumento;
		width: 100%;
	}


	&__data{
		grid-area: data;
	}

}
