@import 'assets/styles/colors.scss';

.list-row {
  height: 40px;
  padding-left: 24px;
  cursor: pointer;
  border: 1px solid $secundary_color_100_50;
  &:hover {
    background: $secundary_color_100_24;
  }

  &__column {
    color: #555770;
    font-weight: 400;
    font-size: 14px;
    padding-top: 4px;
    line-height: 24px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  &__id {
    @extend .list-row__column;
    grid-area: id;
    width: 50px
  }
  &__produto {
    @extend .list-row__column;
    grid-area: produto;
    min-width: 232px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  &__aprovacao {
    @extend .list-row__column;
    grid-area: aprovacao;
    min-width: 80px;
  }
  &__valor {
    @extend .list-row__column;
    grid-area: valor;
    min-width: 130px;
  }
  &__vencimento {
    @extend .list-row__column;
    grid-area: vencimento;
    min-width: 90px;
  }
  &__status {
    @extend .list-row__column;
    grid-area: status;
    min-width: 200px;
  }
}

.circle-alert{
  margin-left: 4px;
  margin-top: 4px;
  cursor: default;
}