@import 'assets/styles/colors.scss';

.dashboard-floor-plan__legenda-valorDuplicata{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	
	&__item{
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;

		.azul{
			border-radius: 50%;
			width: 9px;
			height: 9px;
			background-color: $primary_color_600;
			margin-right: 8px;
			display: inline;
		}

		.amarelo{
			border-radius: 50%;
			width: 9px;
			height: 9px;
			background-color: $alert_color_200_80;
			margin-right: 8px;
		}

		span{
			font-weight: 450;
			font-size: 12px;
			line-height: 16px;
			color: #555770;
		}
	}
}
