.comunicados__filtros__container {
	justify-content: center;
	margin: 0 40px;

	&__date{
		grid-area: data;
		margin-right: 8px;
		width: 100%;
		.date-picker-container{
			width: 426px;
		}
	}

	&__brand{
		grid-area: brand
	}

	&__documento{
		margin-right: 8px;
		grid-area: documento;
	}

	&__button{
		grid-area: button;
		margin-left: 8px;
		padding-top: 20px;
	}

}


