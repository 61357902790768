.common__modal__overlay {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: rgba(85, 87, 112, 0.4);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.common__modal__container {
  width: 55vw;
  height: 64vh;
  overflow: auto;

  background-color: white;
  border-radius: 8px;

  position: relative;
}

.common__modal__close {
  z-index: 1000;
  position: absolute;
  top: 18px;
  right: 24px;

  width: 36px;
  height: 40px;

  color: #555770;
}

.common__modal__content {
  width: 100%;
  height: 100%;
  display: flex;
}
