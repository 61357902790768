.dashboard-floor-plan__duplicatas-vencidas__content-cards{
	display: grid;
	margin: 32px 0 16px;
	column-gap: 8px;
	grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
	grid-template-areas: "vencida today dezDias vinteDias";
	width: 100%;

	.card-vencidas{
		grid-area: vencida;
	}		

	.card-today{
		grid-area: today;
	}

	.card-dez-dias{
		grid-area: dezDias;
	}

	.cards-vinte-dias{
		grid-area: vinteDias;
	}
}
