.user-feedback-popover__container{
  width: 320px;
  height: 480px;
  position: fixed;
  bottom: 86px;
  right: 32px;

  border-radius: 6px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  background-color: white;

  overflow: hidden;
}