.alteracao-limite-form__dados-solicitacao{
    color: #555770;
    display: grid;
    width: 100%;
  
    grid-template-columns:  230px 1fr ;
    grid-column-gap: 10px;
    grid-template-rows: auto;
  
    grid-template-areas:
      "title    title"
      "tipo    motivo"
      "valor   motivo"
      "produto  produto";

    &_title{
        grid-area: title;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        margin-bottom: 13px;
    }
    &_tipo{
        grid-area: tipo;
    }
    &_valor{
        grid-area: valor;
        margin-top:-2px
    }
    &_motivo{
        grid-area: motivo;
    }
    &_produto{
        margin-top: 20px;
        height: 50px;
        grid-area: produto;
    }
}