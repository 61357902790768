
@import "../../../../../../node_modules/slick-carousel/slick/slick.css";
@import "../../../../../../node_modules/slick-carousel/slick/slick-theme.css";
@import 'assets/styles/colors.scss';


.td-page-offer-card-container{
  width: 90%;
  padding-bottom: 32px;
  position: relative;
  .slick-track{
    display: flex;
    flex-flow: row;
    .slick-slide{
      margin:16px;
    }
  }
}

.slick-next:before, .slick-prev:before{
  color:$primary_color_500;
  font-size: 24px;
}
