.menu-aside-btn {
  cursor: pointer;
  width: 100%;
  height: 64px;
  position: relative;

  &-icon {
      width: 20px;
      height: 20px;
      margin: auto auto;
      color: #ffffff;
      padding-top: 11px;
      margin-bottom: 4px;
      svg {
          width: 20px;
          height: 20px;
      }
  }

  &-label {
      max-width: 48px;
      max-height: 20px;
      width: 48px;
      height: 20px;
      font-size: 8px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      margin: auto auto;
  }

  &-active {
      width: 4px;
      height: 40px;
      border-radius: 4px;
      background-color: #ffffff;
      position: absolute;
      left: 0;
      margin-top: 11px;
  }

  &-margin{
      margin-bottom: 60px;
  }
}
