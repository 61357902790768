.payment-receipt-page {
  width: 100%;
  height: 100vh;
}

.payment-receipt-page-inner {
  width: 100%;
  height: 100%;

  overflow-y: hidden;

  display: grid;
  grid-template-rows: auto 1fr 64px;
  grid-template-areas: 
  "header"
  "body"
  "footer";
}

.payment-receipt {
  &-body {
    grid-area: body;
    margin-left: 100px;
    overflow-y: auto;
  }

  &-footer {
    grid-area: footer;
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;

    &-button{
      &-dowloand{
        right: 10%;
        position: absolute;
        margin-right: 100px;
        height: 38px;
        width: 180px;

        &-centralized{
          right: 10%;
          margin-right: 100px;
          bottom: 8%;
          position: absolute;
          height: 38px;
          width: 180px;

        }
      }

      &-close{
        right: 10%;
        position: absolute;
        height: 38px;
        width: 75px;

        &-centralized{
          right: 10%;
          bottom: 8%;
          position: absolute;
          height: 38px;
          width: 75px;
        }
      }
    }
  }
}

.payment-receipt-header-centralized {
  position: absolute;
  left: 14%;
  top: 26%;
}

.payment-receipt-footer-error-span-centralized{
  right: 10%;
  margin-right: 100px;
  bottom: 4%;
  position: absolute;
  font-size: 12px;
  line-height: 1.69;
}
