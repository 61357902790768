.payment-receipt-body {
  &-info{
    &-tittle{
      font-size: 16px;
      line-height: 1.75;
      color: rgba(0, 0, 0, 0.82);
      margin-bottom: 16px;
    }

    &-values{
      font-size: 14px;
      line-height: 1.43;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.6);
    }

    &-block{
      display: inline-block;
    }
  }
}