.solicitacao-limite__menu__container {
    height: 100%;
    width: 100%;
    background-color: #f7f9fc;
}

.solicitacao-limite__menu__item {
    padding: 4px 8px;
}

.solicitacao-limite__menu__container__content {
    padding-top: 14px;

}