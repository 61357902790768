.pedidos__acompanhamento-list__container {
	height: 100%;
  width: 100%;
	min-width: 1294px;
	&__open {
		height: 100%;
		width: 100%;
		min-width: 700px;
	}
}

.pedidos__acompanhamento-list__container__list {
	&__veiculo {
		display: flex;
		flex-direction: row;
		gap: 8px;
		align-items: center;
		&__mvs {
			font-size: 12px;
		}
		&__nome {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			text-transform: capitalize;
			@media (min-width: 1600px) {
				max-width: 400px
			}
			@media (max-width: 1600px) {
				max-width: 300px
			}
		}
	}
	
	&__concessionaria {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 120px
	}
	
	&__codigo {
		font-size: 12px;
	}
}
