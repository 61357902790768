.payment-receipt-header {
  grid-area: header;
  margin-bottom: 36px;
  margin-top: 16px;
  margin-left: 100px;

  &-image{
    grid-area: header-content;
    display: inline-flex;
    height: 25px;
    width: 200px;
    margin-right: 77px;
  }

  &-partition{
    margin-top: 24px;
    grid-area: header-content;
    vertical-align: bottom;
    margin-right: 24px;
    display: inline-flex;
    width: 1px;
    height: 48px;
    background-color: #d8d8d8;
  }

  &-span{
    grid-area: header-content;
    display: inline-flex;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #00afad;
  }
}